
import { ServerError } from "layout/ServerError";
import {
  GoogleSignIn,
  OffboardingPage, OnboardingPage, TimesheetDelayReport, RecruitmentBoard, uploaddocuments,
  DelayAttendanceReport
} from "views";
import SpecificRegularizeIdDetails from "views/Biometric/SpecificRegularizeIdDetails";
import LeaveReconcilliationMaster from "views/Leave-Reconcilation/LeaveReconcilliationMaster";
import LeaveReport from "views/Leave-Reconcilation/LeaveReport";
import UploadLeaveDocument from "views/Leave-Reconcilation/UploadLeaveDocument";
import TotalHoursReport from "views/Reports/TotalHoursReport";
import TeamMembers from "views/On-Offboarding/Team-members/TeamMembers";
import WfhList from "views/Work-from-home/WfhList";
import HistoryRequestesList from "views/Work-from-home/HistoryRequestesList";
import SpecificWfhIdDetails from "views/Work-from-home/SpecificWfhIdDetails";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import RegularizationList from "views/Biometric/RegularizationList";

const routes = [
  {
    auth: false,
    path: "/login",
    exact: true,
    component: GoogleSignIn,
    redirectTo: '/onboarding',
  },
  {
    auth: false,
    path: "/",
    exact: true,
    component: GoogleSignIn,
    redirectTo: '/onboarding',
  },
  {
    path: "/server-error",
    exact: true,
    component: ServerError,
  },
  {
    auth: true,
    path: "/onboarding",
    exact: true,
    component: OnboardingPage,
    access: [1, 2],
  },
  {
    auth: true,
    path: "/offboarding",
    exact: true,
    component: OffboardingPage,
    access: [1, 2],
  },
  {
    auth: true,
    path: "/timesheetdelayreport",
    exact: true,
    component: TimesheetDelayReport,
    access: [1, 2],
  },
  {
    auth: true,
    path: "/recruitmentboard",
    exact: true,
    component: RecruitmentBoard,
    access: [1, 2],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.DelayAttendanceReport,
    exact: true,
    component: DelayAttendanceReport,
    access: [1, 2, 3],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.RegularizationList,
    exact: true,
    component: RegularizationList,
    access: [1, 2],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.UploadDocuments,
    exact: true,
    component: uploaddocuments,
    access: [1, 2],
  },
  {
    auth: true,
    path: "/regularize/details/:id",
    exact: true,
    access: [1, 2],
    component: SpecificRegularizeIdDetails,
  },
  {
    auth: true,
    path:"/totalhoursreport",
    exact: true,
    component: TotalHoursReport,
    access : [1,2],
  },
  {
    auth: true,
    path:"/leave-reconcilliation-master-sheet",
    exact: true,
    component: LeaveReconcilliationMaster,
    access : [1,2],
  },
  {
    auth: true,
    path:"/leave-data",
    exact: true,
    component: LeaveReport,
    access : [1,2],
  },
  {
    auth: true,
    path:"/upload-leave-document",
    exact: true,
    component: UploadLeaveDocument,
    access : [1,2],
  },
  {
    auth: true,
    path: "/team-members",
    exact: true,
    component: TeamMembers,
    access: [1, 2],
  },
  {
    auth: true,
    path: "/wfh/details/:id",
    exact: true,
    access: [1, 2],
    component: SpecificWfhIdDetails,
  },
  {
    auth: true,
    path: "/history-wfh-requests",
    exact: true,
    access: [1, 2, 3],
    component: HistoryRequestesList,
  },
  {
    auth: true,
    path: "/wfh-list",
    exact: true,
    component: WfhList,
    access: [1, 2],
  },
];

export { routes };
