enum LeftNavRoutesEnum {
  ONBOARDING = "/onboarding",
  OFFBOARDING = "/offboarding",
  TIMESHEETDELAYREPORT = "/timesheetdelayreport",
  DelayAttendanceReport = "/biometric/daily-attendance",
  RegularizationList = "/biometric/regularization-list",
  UploadDocuments = "/biometric/upload-documents",
  RECRUITMENT = "/recruitmentboard",
  TotalHoursReport = "/totalhoursreport",
  LEAVE_RECONCILIATION = "/leave-data",
  LeaveReconcilliationMaster = "/leave-reconcilliation-master-sheet",
  LEAVE_TRACKER = '/leave-tracker',
  UPLOAD_LEAVE_DOCUMENT = "/upload-leave-document",
  TEAM_MEMBERS = "/team-members",
  WFH_LIST = "/wfh-list",
  HISTORY_WFH_REQUESTS = "/history-wfh-requests",
  WORK_FROM_HOME = "/work-from-home",
}

enum LeftNavMenuKeysEnum {
  ONBOARDING = "/onboarding",
  OFFBOARDING = "/offboarding",
  TIMESHEETDELAYREPORT = "/timesheetdelayreport",
  RECRUITMENT = "/recruitmentboard",
  DelayAttendanceReport = "/biometric/daily-attendance",
  RegularizationList = "/biometric/regularization-list",
  UploadDocuments = "/biometric/upload-documents",
  REPORT = "/report",
  BIOMETRIC = "/biometric",
  TotalHoursReport = "/totalhoursreport",
  LEAVE_RECONCILIATION = "/leave-data",
  LeaveReconcilliationMaster = "/leave-reconcilliation-master-sheet",
  LEAVE_TRACKER = '/leave-tracker',
  UPLOAD_LEAVE_DOCUMENT = "/upload-leave-document",
  TEAM_MEMBERS = "/team-members",
  WFH_LIST = "/wfh-list",
  HISTORY_WFH_REQUESTS = "/history-wfh-requests",
  WORK_FROM_HOME = "/work-from-home",
}

export { LeftNavRoutesEnum, LeftNavMenuKeysEnum };