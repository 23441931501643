import { RegularizationRequestService, WfhService, BiometricTotalHoursData, TeamMembersService, LeaveReconcilliationService, JoinersListOnboardingService, TimesheetDataService, BiometricAttendanceService } from "services";

export const apiServicesUtils = {

    // RESEND_UPLOAD_ONBOARDING_DOCUMENT_EMAIL: {
    //     class: JoinersListOnboardingService,
    //     method: 'resendUploadOnboardingDocumentEmail'
    // },
    // GET_CANDIDATE_DATA: {
    //     class: JoinersListOnboardingService,
    //     method: 'getCandidateDetails'
    // },

    // GET_FOLDER_NAMES_AND_UPLOADED_FILES: {
    //     class: JoinersListOnboardingService,
    //     method: 'getUploadedFilesAndFolderNames'
    // },


    // UPLOAD_ONBOARDING_DOCUMENTS: {
    //     class: JoinersListOnboardingService,
    //     method: 'postOnboardingDocuments'
    // },

    //     DELETE_ONBOARDING_DOCUMENTS: {
    //     class: JoinersListOnboardingService,
    //     method: 'deleteFileFromGdrive'
    // },

    GET_TEAM_MEMBERS: {
        class: TeamMembersService,
        method: 'getTeamMembers'
    },
    GET_BIOMETRIC_TOTAL_HOURS: {
        class: BiometricTotalHoursData,
        method: 'getBiometricTotalHoursData'
    },
    
    
    GET_LEAVE_DATA: {
        class: LeaveReconcilliationService,
        method: 'getLeaveData'
    },


    GET_TIMESHEET_DELAY_DATA: {
        class: TimesheetDataService,
        method: 'getTimesheetDelayData'
    },

    
    CREATE_REGULARIZATION_REQUEST: {
        class: RegularizationRequestService,
        method: 'createRegularisationRequest'
    },
    GET_REGULARIZATION_REQUEST: {
        class: RegularizationRequestService,
        method: 'getRegularizationRequest'
    },
    UPDATE_REGULARIZATION_REQUEST_STATUS: {
        class: RegularizationRequestService,
        method: 'updateRegularizationRequest'
    },

    
    GET_REGULARIZATION_REQUEST_LIST: {
        class: RegularizationRequestService,
        method: 'getRegularizationRequest'
    },


    GET_DAILY_ATTENDANCE_DATA: {
        class: BiometricAttendanceService,
        method: 'getAttendanceReport'
    },


    GET_WFH_REQUEST_LIST: {
        class: WfhService,
        method: 'getWfhRequestData'
    },
    
    UPDATE_WFH_REQUEST_STATUS: {
        class: WfhService,
        method: 'updateWfhDetails'
    },

    GET_WFH_REQUEST_HISTORY: {
        class: WfhService,
        method: 'getWfhHistoryRequests'
    },
    DELETE_WFH_REQUEST_DATA: {
        class: WfhService,
        method: 'deleteWfhRequests'
    },

    CREATE_WFH_REQUEST_DOCUMENT_UPLOAD: {
        class: WfhService,
        method: 'uploadImageToCloud'
    },

    CREATE_WFH_REQUEST_DATA_UPLOAD: {
        class: WfhService,
        method: 'createWfhRequest'
    }
}