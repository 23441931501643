import { RoutesEnum } from "models/enums";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

export class UploadBiometricDataService{
  public postBiometricData = async (uploadBiometricData: {}, date_for: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.POST_BIOMETRIC_DATA,
      uploadBiometricData,
      {
        params: date_for,
      }
    );
  };
}