import { Layout, Menu } from "antd";
import {
  isHamMenuVisible,
  isLeftPanelVisible,
  toggleHamMenu,
  toggleLeftPanel,
} from "models/Slices/GlobalSlice";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { Link, useLocation } from "react-router-dom";
import { IMenu } from "models/interfaces";
// menuTeamMember
import { Children, useEffect, useState } from "react";
import { LeftNavMenuKeysEnum } from "models/enums/leftNavRoutes";
import Logo from "assets/images/logo-light.svg";
import { initialMenu } from "./LayoutUtils";
import {
  useGoogleSignIn,
  useTokenID,
  verifyTokenID,
} from "utils/auth/google.security";
const { Sider } = Layout;



const AppLeftPanel: React.FC<{
  defaultOpenKeys: string[];
  setDefaultOpenKeys: Function;
  selectedKeys: string[];
  setSelectedKeys: Function;
}> = ({
  defaultOpenKeys,
  setDefaultOpenKeys,
  selectedKeys,
  setSelectedKeys,
}) => {
    const { handleSignOut } = useGoogleSignIn();
    const userInfo = useTokenID();
    const { sub: { role_id } } = userInfo;
    const leftPanelActive = useAppSelector(isLeftPanelVisible);
    const location = useLocation();
    const dispatch = useAppDispatch();
    const hamMenuActive = useAppSelector(isHamMenuVisible);
    const [menu, setMenu] = useState<IMenu[]>([]);



    function findLeftPanel(menuArray: any, role_id: number){
      let leftPanel: any [] = [];
      menuArray.forEach((curr_elem: any) => {
        
        if(curr_elem.childrens){
          const childrens: any [] = findLeftPanel(curr_elem.childrens, role_id);
          
          if(childrens?.length > 0)
            leftPanel = [...leftPanel, {...curr_elem, childrens }];
        }
        else {
          if (curr_elem?.accassableTo?.includes(role_id)){
            leftPanel = [...leftPanel, curr_elem];
          }
        }
      });
      return leftPanel;
    };

    

    useEffect(() => {
      if (verifyTokenID()) {
        const menu: IMenu[] = findLeftPanel(initialMenu, role_id);
        setMenu(
          menu?.sort((a, b) => a.displayOrder - b.displayOrder)?.map((menu) => {
            if (menu.childrens?.length) {
              menu.childrens = menu.childrens.sort(
                (x, y) => x.displayOrder - y.displayOrder
              );
            }
            return menu;
          })
        );
      }
    }, []);



    useEffect(() => {
      const path = location.pathname;
      switch (path.split("/")[1]) {
        case "onboarding":
          setDefaultOpenKeys([LeftNavMenuKeysEnum.ONBOARDING]);
          break;
        case "offboarding":
          setDefaultOpenKeys([LeftNavMenuKeysEnum.OFFBOARDING]);
          break;
        case "team-members":
          setDefaultOpenKeys([LeftNavMenuKeysEnum.TEAM_MEMBERS]);
          break;
        case "recruitmentboard":
          setDefaultOpenKeys([LeftNavMenuKeysEnum.REPORT]);
          break;
        case "timesheetdelayreport":
          setDefaultOpenKeys([LeftNavMenuKeysEnum.REPORT]);
          break;
        case "daily-attendance":
          setDefaultOpenKeys([LeftNavMenuKeysEnum.BIOMETRIC]);
          break;
        case "regularizationdetails":
          setDefaultOpenKeys([LeftNavMenuKeysEnum.BIOMETRIC]);
          break;
        case "uploaddocuments":
          setDefaultOpenKeys([LeftNavMenuKeysEnum.BIOMETRIC]);
          break;
        case "totalhoursreport":
          setDefaultOpenKeys([LeftNavMenuKeysEnum.REPORT]);
          break;
        case "leave-data":
          setDefaultOpenKeys([LeftNavMenuKeysEnum.LEAVE_TRACKER]);
          break;
        case "leave-reconcilliation-master-sheet":
          setDefaultOpenKeys([LeftNavMenuKeysEnum.LEAVE_TRACKER]);
          break;
        case "upload-leave-document":
          setDefaultOpenKeys([LeftNavMenuKeysEnum.LEAVE_TRACKER]);
          break;
        case "history-wfh-requests":
          setDefaultOpenKeys([LeftNavMenuKeysEnum.WORK_FROM_HOME]);
          break;
        case "wfh-list":
          setDefaultOpenKeys([LeftNavMenuKeysEnum.WORK_FROM_HOME]);
          break;
        default:
          setDefaultOpenKeys([LeftNavMenuKeysEnum.ONBOARDING]);
          break;
      }

      const pathList = path.split('/');

      if(pathList.includes('biometric')){
        setDefaultOpenKeys([LeftNavMenuKeysEnum.BIOMETRIC]);
      }

      setSelectedKeys([path]);
    }, [location.pathname]);


    return (
      <Sider
        width={260}
        className={`sidebarSiderWrap mobNav ${hamMenuActive ? "active" : ""}`}
        collapsed={leftPanelActive}
      >
        <div className="sidebarHeader">
          <a href="#/" className="logo" onClick={(e) => e.preventDefault()}>
            <img src={Logo} alt="" title="Ticket Bash" />
          </a>
          <button
            type="button"
            className={"toggleSidebarBtn" + (leftPanelActive ? " active" : "")}
            onClick={() => {
              dispatch(toggleLeftPanel(leftPanelActive));
            }}
          >
            <i></i>
          </button>
          <span
            className={`closeSidebarBtn ${hamMenuActive ? "active" : ""}`}
            onClick={() => {
              dispatch(toggleHamMenu(hamMenuActive));
            }}
          >
            <i className="da icon-close-white" />
          </span>
        </div>
        <div className="sidebarLogo">
          <a href="#/" className="logo" onClick={(e) => e.preventDefault()}>
            <img src={Logo} alt="" title="Ticket Bash" />
          </a>
        </div>
        <Menu
          mode="inline"
          className="leftMenu"
          triggerSubMenuAction={"click"}
          defaultOpenKeys={defaultOpenKeys}
          openKeys={defaultOpenKeys}
          selectedKeys={selectedKeys}
          onOpenChange={(openKeys) => {
            if (openKeys.length > 0) {

              if (openKeys[openKeys.length - 1].toString() === '/biometric-submenu') {
                setDefaultOpenKeys(openKeys);

              } else {
                setDefaultOpenKeys([openKeys[openKeys.length - 1].toString()]);
              }
            } else {
              setDefaultOpenKeys([]);
            }
            setSelectedKeys([location.pathname]);
          }}
          onClick={(a) => {

            setSelectedKeys([a.key]);
            if (a.key === '/totalhoursreport') {
              setDefaultOpenKeys([a.keyPath[2], a.keyPath[1]]);
            }
            else {
              setDefaultOpenKeys([a.keyPath[1]]);
            }

          }}
        >
          {menu &&
            menu.map((menuItem) => {

              if (menuItem.childrens && menuItem.childrens.length > 0) {
                return (
                  <Menu.SubMenu
                    key={menuItem.key}
                    icon={<i className={menuItem.iconClass} />}
                    title={menuItem.menuTitle}
                    onTitleClick={() => {
                      setSelectedKeys(menuItem.key);
                    }}
                  >
                    {menuItem.childrens.map((childItem) => {
                      if (childItem.childrens && childItem.childrens.length > 0) {

                        return (
                          <Menu.SubMenu
                            key={childItem.key}
                            icon={<i className={childItem.iconClass} />}
                            title={childItem.menuTitle}
                            onTitleClick={() => {
                              setSelectedKeys(childItem.key);
                            }}
                          >
                            {childItem.childrens.map((grandChild) => {

                              return (
                                <Menu.Item key={grandChild.key} icon={<i className={grandChild.iconClass} />} >
                                  {grandChild.navigationLink ? (
                                    <Link to={grandChild.navigationLink}>
                                      {grandChild.menuTitle}
                                    </Link>
                                  ) : (
                                    <span>{grandChild.menuTitle}</span>
                                  )}
                                </Menu.Item>)
                            })}
                          </Menu.SubMenu>
                        );
                      } else {
                        return (
                          <Menu.Item
                            key={childItem.key}
                            icon={<i className={childItem.iconClass} />}
                          >
                            {childItem.navigationLink ? (
                              <Link to={childItem.navigationLink}>
                                {childItem.menuTitle}
                              </Link>
                            ) : (
                              <span>{childItem.menuTitle}</span>
                            )}
                          </Menu.Item>
                        );
                      }
                    })}
                  </Menu.SubMenu>
                );
              } else {
                return (
                  <Menu.Item
                    key={menuItem.key}
                    icon={<i className={menuItem.iconClass} />}
                  >
                    {menuItem.navigationLink ? (
                      <Link to={menuItem.navigationLink}>
                        {menuItem.menuTitle}
                      </Link>
                    ) : (
                      <span>{menuItem.menuTitle}</span>
                    )}
                  </Menu.Item>
                );
              }
            })}

        </Menu>
        <div className="leftPanelFooter forMob">
          <span className="avatarIcon" title={userInfo?.sub?.name}>
            <i className="da icon-user-w" />
          </span>
          <span className="logOut" onClick={() => handleSignOut()}>
            Logout
          </span>
        </div>
      </Sider>
    );
  };

export default AppLeftPanel;
