import UploadComponent from './UploadComponent';
import { usePageTitle } from 'hooks';
import { Constants } from 'views/constants';

export default function UploadDocuments() {

    usePageTitle(Constants.UPLOAD_ATTENDANCE_DOCUMENTS_TITLE);

    
    return (
        <div>
            <h3>{Constants.UPLOAD_ATTENDANCE_DOCUMENTS}</h3>      
            <UploadComponent/>
        </div>
    );
}




