import { FC, useEffect, useState } from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router";
import { useTokenID, verifyTokenID } from "utils/auth/google.security";
import { getEncodedData } from "utils/commonFunction";
import { Forbidden } from "./Forbidden";
import { useRbac } from "features/rbac/rbac";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";




export const AuthenticateRoute: FC<any> = ({
  component: Component, ...rest
}) => {
  const location = useLocation();
  const { getUserRole } = useRbac();
  const{ role_id } = getUserRole();
  
  
  return (
    <Route
      {...rest}
      render={(props) =>
        Component && verifyTokenID()? 
        ( 
          role_id && rest.access?.includes(role_id)?
            <Component {...props} />
            :
            <Forbidden />
        ) : 
        (
          <Redirect
          to={`/login?msg=${getEncodedData("Your session expired")}&redirectTo=${getEncodedData(location.pathname)}`}
          />
        )
      }
    />
  );
};
      
      
      
export const AnonymousRoute: FC<any> = ({ component: Component, ...rest }) => {   
  const userInfo = useTokenID();

  return (
    <Route
      {...rest}
      render={(props) =>
        Component ? (
          rest.redirectTo === "/onboarding" && verifyTokenID() ? (
            userInfo?.sub?.role_id === 3?
              <Redirect to={LeftNavRoutesEnum.DelayAttendanceReport} />
            :
              <Redirect to={rest.redirectTo} />
          ) : (
            <Component {...props} />
          )
        ) : null
      }
    />
  );
};
