import { LeftNavMenuKeysEnum, LeftNavRoutesEnum } from "models/enums/leftNavRoutes";

export const initialMenu = [
    {
      displayOrder: 1,
      menuTitle: "Onboarding",
      iconClass: "da icon-home",
      key: LeftNavMenuKeysEnum.ONBOARDING,
      navigationLink: LeftNavRoutesEnum.ONBOARDING,
      accassableTo: [1, 2],
    },
    {
      displayOrder: 2,
      menuTitle: "Offboarding",
      iconClass: "da icon-settings",
      key: LeftNavMenuKeysEnum.OFFBOARDING,
      navigationLink: LeftNavRoutesEnum.OFFBOARDING,
      accassableTo: [1, 2],
    },
    {
      displayOrder: 3,
      menuTitle: "Team Members",
      iconClass: "da icon-settings",
      key: LeftNavMenuKeysEnum.TEAM_MEMBERS,
      navigationLink: LeftNavRoutesEnum.TEAM_MEMBERS,
      accassableTo: [1, 2],
    }, 
    {
      displayOrder: 4,
      menuTitle: "Report",
      iconClass: "da icon-report-2nd",
      key: LeftNavMenuKeysEnum.REPORT,
      childrens: [
        {
          displayOrder: 1,
          menuTitle: "Recruitment",
          iconClass: "da icon-report-2nd",
          key: LeftNavMenuKeysEnum.RECRUITMENT,
          navigationLink: LeftNavRoutesEnum.RECRUITMENT,
          accassableTo: [1, 2],
        },
        {
          displayOrder: 2,
          menuTitle: "Timesheet Delay",
          iconClass: "da icon-report-2nd",
          key: LeftNavMenuKeysEnum.TIMESHEETDELAYREPORT,
          navigationLink: LeftNavRoutesEnum.TIMESHEETDELAYREPORT,
          accassableTo: [1, 2],
        },
        {
          displayOrder: 3,
          menuTitle: "Biometric - Total Hours",
          iconClass: "da icon-report-2nd",
          key: LeftNavMenuKeysEnum.TotalHoursReport,
          navigationLink: LeftNavRoutesEnum.TotalHoursReport,
          accassableTo: [1, 2],
        }
      ]
    },
    {
      displayOrder: 5,
      menuTitle: "Leave Tracker",
      iconClass: "da icon-leave-calander",
      key: LeftNavMenuKeysEnum.LEAVE_TRACKER,
      childrens: [
        {
          displayOrder: 1,
          menuTitle: "Leave Reconciliation Master",
          iconClass: "da icon-leave-calander",
          key: LeftNavMenuKeysEnum.LeaveReconcilliationMaster,
          navigationLink: LeftNavRoutesEnum.LeaveReconcilliationMaster,
          accassableTo: [1, 2],
        }, 
        {
          displayOrder: 2,
          menuTitle: "Leave Reconciliation",
          iconClass: "da icon-leave-calander",
          key: LeftNavMenuKeysEnum.LEAVE_RECONCILIATION,
          navigationLink: LeftNavRoutesEnum.LEAVE_RECONCILIATION,
          accassableTo: [1, 2],
        }, 
        {
          displayOrder: 3,
          menuTitle: "Upload Leave Data",
          iconClass: "da icon-upload-document",
          key: LeftNavMenuKeysEnum.UPLOAD_LEAVE_DOCUMENT,
          navigationLink: LeftNavRoutesEnum.UPLOAD_LEAVE_DOCUMENT,
          accassableTo: [1, 2],
        }, 
      ]

    },
    
    {
      displayOrder: 6,
      menuTitle: "Biometric",
      iconClass: "da icon-biometric",
      key: LeftNavMenuKeysEnum.BIOMETRIC,
      childrens: [
        {
          displayOrder: 1,
          menuTitle: "Daily Attendance",
          iconClass: "da icon-biometric",
          key: LeftNavMenuKeysEnum.DelayAttendanceReport,
          navigationLink: LeftNavRoutesEnum.DelayAttendanceReport,
          accassableTo: [1, 2, 3],
        },
        {
          displayOrder: 2,
          menuTitle: "Regularization List",
          iconClass: "da icon-settings",
          key: LeftNavMenuKeysEnum.RegularizationList,
          navigationLink: LeftNavRoutesEnum.RegularizationList,
          accassableTo: [1, 2],
        },
        {
          displayOrder: 3,
          menuTitle: "Upload Documents",
          iconClass: "da icon-settings",
          key: LeftNavMenuKeysEnum.UploadDocuments,
          navigationLink: LeftNavRoutesEnum.UploadDocuments,
          accassableTo: [1, 2],
        }, 
        
      ]
    },
    {
      displayOrder: 7,
      menuTitle: "Work From Home",
      iconClass: "da icon-report",
      key: LeftNavMenuKeysEnum.WORK_FROM_HOME,
      childrens: [
        {
          displayOrder: 1,
          menuTitle: "WFH History",
          iconClass: "da icon-settings",
          key: LeftNavMenuKeysEnum.HISTORY_WFH_REQUESTS,
          navigationLink: LeftNavRoutesEnum.HISTORY_WFH_REQUESTS,
          accassableTo: [1, 2, 3],
        },
        {
          displayOrder: 2,
          menuTitle: "Pending Requests",
          iconClass: "da icon-settings",
          key: LeftNavMenuKeysEnum.WFH_LIST,
          navigationLink: LeftNavRoutesEnum.WFH_LIST,
          accassableTo: [1, 2],
        }
      ]
    }
  ]
