import { usePageTitle } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { Constants } from '../../constants';
import LoadingSpinner from 'utils/sharedComponents/LoadingSpinner';
import { useApiServices } from 'hooks/useApiServices';
import { Input, Layout, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import ExcelJS from 'exceljs';
import NoDataFound from 'views/NoDataFound';
import { getIconClass, getSortArray } from 'utils/commonFunction';
import { ITeamMemberInputProps } from 'models/interfaces/TeamMembers/teamMembers.model';
import CommonDrawer from 'utils/sharedComponents/CommonDrawer';


const tableDataStles = {
    Active: {
        color: '#09ae6c'
    },
    Inactive: {
        color: 'rgb(202, 22, 64)'
    },
    'Official Mail': {
        width: '350px'
    },
    Designation: {
        width: '250px'
    },
    Name: {
        width: '250px'
    }
}

const TeamMembers: React.FC = () => {
    usePageTitle(Constants.TEAM_MEMBERS_LIST);
    const [sort, setSort] = useState<{ keyToSort: string, direction: string }>({ keyToSort: "Name", direction: "asc" });
    const { data, fetchData, isLoading, isError } = useApiServices()
    const header = [
        'Name',
        'Official Mail',
        'Employee Id',
        'Designation',
        'Department',
        'Location',
        "Status"
    ]
    const [searchTimeOut, setSearchTimeOut] = useState<any>(null);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const defaultFilterParams = () => ({ status: 'active', searchText: '' })
    const [filterData, setFilterData] = useState<{ status: string | null, searchText: string }>(defaultFilterParams());
    const [tempFilterData, setTempFilterData] = useState<{ status: string | null, searchText: string }>(defaultFilterParams());
    const [tableData, setTableData] = useState<any>([]);

    const drawerInputs: ITeamMemberInputProps[] = [
        {
            type: "singleSelect",
            label: "Status",
            keyName: "status",
            options: [
                { 'label': 'All', 'value': 'all' }, 
                { 'label': 'Active', 'value': 'active' }, 
                { 'label': 'Inactive', 'value': 'inactive' }
            ],
            show: true,
            disable: false,
            defaultValue: true,
            default: 'All'
        }
    ]


    const structureData = useCallback((teamMembersInfo: any, filterData: any) => {
        
        let updatedData = teamMembersInfo ?? [];

        if (filterData.searchText !== null && filterData.searchText !== '') {
            updatedData = teamMembersInfo.filter((curr_row: any) => (
                curr_row['Name']?.toLowerCase()?.includes(filterData.searchText.toLowerCase())
                ||
                curr_row['Designation']?.toLowerCase()?.includes(filterData.searchText.toLowerCase())
                ||
                curr_row['Official Mail']?.toLowerCase()?.includes(filterData.searchText.toLowerCase())
            ));
        }
        if (filterData.status !== 'all') {
            updatedData = updatedData.filter((member: any) => member['Status'].toLowerCase() === filterData.status);
        }
        return updatedData;
    }, []);



    function handleSortClick(header: string) {
        let direction = 'desc';
        if(sort.keyToSort && sort.direction === 'desc'){
            direction = 'asc';
        }

        setSort({
            keyToSort: header,
            direction
        })
    }



    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (searchTimeOut) {
            clearTimeout(searchTimeOut);
        }
        const value: string = e.target.value;
        const ele: any = e.nativeEvent;
        const key: any = ele.data;
        const inputType = ele.inputType;
        if (
            key !== null &&
            inputType !== "deleteContentBackward" &&
            (value === "" || value?.length < 3)
        ) {
            setFilterData((prev: any) => {
                return (
                    { ...filterData, searchText: "" }
                )
            })
            return;
        }
        setSearchTimeOut(
            setTimeout(
                () => {
                    setFilterData((prev: any) => {
                        return (
                            { ...filterData, searchText: value }
                        )
                    })
                },
                1200
            )
        );
    }



    const calcelFilter = () => {
        setIsFilterOpen(false);
        setFilterData({ ...defaultFilterParams(), searchText: filterData.searchText })
        setTempFilterData(defaultFilterParams())
    }
    const submitFilter = () => {
        setIsFilterOpen(false);
        setFilterData({ ...tempFilterData, searchText: filterData.searchText })
    }

    const exportToExcelFile = (data: any[]) => {
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const year = currentDate.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Team members List");
    
        const headerColumn: Partial<ExcelJS.Column>[] | { header: any; key: any; width?: number; }[] = [];
        const headerColumns: string[] = header;
    
        header.forEach((header: string) => {
            let width = 25;
            switch(header){
                case 'Employee Id':
                    width = 20;
                    break;
                case 'Status':
                    width = 10;
                    break;
                case 'Official Mail':
                    width = 35;
                    break;
                case 'Location':
                    width = 15;
                    break;
            }

            let obj = {
                header: header,
                key: header,
                width
            };
            headerColumn.push(obj);
        });
    
        sheet.columns = headerColumn;
    
        sheet.getRow(1).height = 20; 
        sheet.getRow(1).font = { bold: true };
        sheet.getRow(1).alignment = { vertical: 'top', horizontal: 'left' };
    
        data?.forEach((curr_data: any, index: number) => {
            const formattedItem = {};
            headerColumns.forEach((key) => {
                const header = key.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
                formattedItem[header] = curr_data[key];
            });
            const row = sheet.addRow(formattedItem);
            row.alignment = { wrapText: true, vertical: 'top', horizontal: 'left' };
    
            row.height = 20; 
        });
    
        let col = sheet.getColumn(7);
        col.eachCell((cell, rowNumber) => {
            if (cell.value === "Active") {
                cell.font = {
                    size: 11,
                    color: { argb: 'ff09ae6c' }
                };
            } else if (cell.value === "Inactive") {
                cell.font = {
                    size: 11,
                    color: { argb: 'ffca1640' }
                };
            }
        });
    
        workbook.xlsx.writeBuffer().then((data: any) => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `team_members_${formattedDate}.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    }

    useEffect(() => {
        if(data){
            const updatedData = structureData(data?.active_members_info, filterData);
            setTableData(updatedData);
        }
    }, [data, filterData, structureData]);
    
    useEffect(() => {
        fetchData('GET_TEAM_MEMBERS');
    }, [fetchData])
    return (
        <>
            {
                isError ?
                    (<React.Fragment>
                        <h2>{isError.code} Error</h2>
                        <div className="errorContent">
                            <div className="errorContentInner">
                                <span className="error-img"></span>
                                <div>
                                    <h3>{isError.message}</h3>
                                    <p>{Constants.BACK_TO_PREVIOUS_PAGE}</p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>)
                    :
                    <Layout className="mainInnerLayout teamMembers">
                        <div className="innerHeader" style={{ 'marginBottom': '18px', paddingTop: '20px' }}>
                            <div className="ant-row alignItemsCenter">
                                <div className="ant-col ant-col-xl-16 flx justifyContentBetween alignItemsCenter">
                                    <h3 style={{ width: "50%" }}>{Constants.TEAM_MEMBERS}</h3>
                                    <Input
                                        placeholder={Constants.SEARCH_LABEL_MASTER_RECONCILIATION}
                                        disabled={isLoading}
                                        allowClear
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="ant-col ant-col-xl-8">
                                    <div className="innerHeaderRight flx alignItemsFlexEnd">
                                        <Button
                                        disabled={isLoading}
                                        className="addUserBtn mr-10 custom-export"
                                        icon={
                                            <DownloadOutlined
                                            style={{ color: 'white', 'paddingTop': '3px' }}
                                            onPointerOverCapture={() => {}} onPointerMoveCapture={() => {}} />
                                        }
                                        onClick={() => exportToExcelFile(tableData)}>Export to XLSX</Button>
                                        <div>
                                            <CommonDrawer
                                            disabled={isLoading}
                                            title={Constants.TEAM_MEMBER_FILTER_DRAWER}
                                            drawerInputs={drawerInputs}
                                            filterData={tempFilterData}
                                            setFilterData={setTempFilterData}
                                            cancelFilter={calcelFilter}
                                            submitFilter={submitFilter}
                                            isFilterOpen={isFilterOpen}
                                            setIsFilterOpen={setIsFilterOpen}
                                            defaultFilterParams={defaultFilterParams}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <LoadingSpinner isLoading={isLoading}>
                                <div className="customTbl userManagementTbl scroll-y tblStickyTHead" >
                                    <table className="main-table">
                                        <thead>
                                            <tr>
                                                {
                                                    header.map((elem, index) => {
                                                        return (
                                                            <th
                                                                key={elem}
                                                                style={tableDataStles[elem] ?? {}}
                                                            >
                                                                {elem}
                                                                {(elem === 'Name' || elem === 'Department' || elem === 'Designation') && (
                                                                    <Button 
                                                                    style={{border: 'none', outline: 'none', background: 'transparent', padding: '0', margin: '0', 'marginLeft': '5px' }}
                                                                    onClick={() => handleSortClick(elem)}
                                                                    >
                                                                        <span className={getIconClass(elem, sort)} ></span>
                                                                    </Button>
                                                                )}
                                                            </th>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                getSortArray(tableData, sort)?.map((emp: any, index) => {
                                                    return (
                                                        <tr key={emp['Employee Id']}>
                                                            {
                                                                header.map((key, index: number) => {
                                                                    return (
                                                                        <td 
                                                                        key={emp[key]} 
                                                                        style={tableDataStles[emp[key]] ?? {}}>{emp[key]}</td>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {(!tableData || tableData.length === 0) && <NoDataFound />}
                                        </tbody>
                                    </table>
                                </div>
                            </LoadingSpinner>
                        </div>
                    </Layout>
            }
        </>
    )
}
export default TeamMembers;