import { useRbac } from 'features/rbac/rbac';
import { IWFHData } from 'models/interfaces/WfhApply/wfhApply.model';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import LoadingSpinner from 'utils/sharedComponents/LoadingSpinner';
import WfhDrawer from 'views/Work-from-home/WfhDrawer';
import { Constants } from 'views/constants';
import { usePageTitle } from 'hooks';
import moment from 'moment';
import { useApiServices } from 'hooks/useApiServices';
import NoDataFound from 'views/NoDataFound';
import { Layout, UploadFile } from 'antd';
import { applyFilter, getWeekDays, getWeekdayString, sortByMultipleFields } from 'utils/commonFunction';
import { Button, Modal } from 'antd';
import { ITeamMemberInputProps } from 'models/interfaces/TeamMembers/teamMembers.model';
import CommonDrawer from 'utils/sharedComponents/CommonDrawer';


const wfhStatusObj = {
    true: {
        text: 'Approved',
        class: 'status-accepted'
    },
    false: {
        text: 'Rejected',
        class: 'status-rejected'
    },
    null: {
        text: 'Pending',
        class: 'status-pending'
    }
};

interface IFilterParams { status: string | null, searchText: string }
interface ISort { keyToSort: string [], direction: string [] }


const styles = {
    reason: {
        minWidth: '120px'
    },
    status: {
        minWidth: '50px'
    },
    from_date: {
        minWidth: '40px',
    },
    to_date: {
        minWidth: '40px'
    },
    updated_by: {
        minWidth: '80px'
    },
    days: {
        minWidth: '100px'
    },
    reject_reason: {
        minWidth: '80px'
    }
}

const HistoryRequestesList = () => {
    usePageTitle(Constants.HISTORY_WFH_TITLE);
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const drawerInputs: ITeamMemberInputProps[] = [
        {
            type: "singleSelect",
            label: "Status",
            keyName: "status",
            options: [
                { 'label': 'All', 'value': 'all' },
                { 'label': 'Pending', 'value': 'pending' },
                { 'label': 'Approved', 'value': 'approved' },
                { 'label': 'Rejected', 'value': 'rejected' }
            ],
            show: true,
            disable: false,
            defaultValue: true,
            default: 'all'
        }
    ]

    const { biometric_emp_code, name, email_id } = useRbac().userDetailsInfo.sub;
    const header = ['from_date', 'to_date', 'days', 'reason', 'updated_by', 'status', 'change_reason', 'delete'];
    const { data, fetchData, isLoading, isError, updateData } = useApiServices();
    const [wfhHistoryDetails, setWfhHistoryDetails] = useState<any>([]);
    const [tableData, setTableData] = useState<any []>([]);
    const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false);
    const [selectedToDelete, setSelectedToDelete] = useState<any>(null);

    // sort and filtration state
    const defaultWfhData = () => ({ start_date: null, end_date: null, reason: '', id: biometric_emp_code, document: null, days: null })
    const defaultFilterParams = () => ({ status: 'all', searchText: '' })
    const [sort] = useState<ISort>({ keyToSort: ["is_approved", "created_on"], direction: ["asc", 'desc'] });
    const [filterParams, setFilterParams] = useState<IFilterParams>(defaultFilterParams());
    const [tempFilterParams, setTempFilterParams] = useState<IFilterParams>(defaultFilterParams());
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState<boolean>(false);


    const [wfhData, setWfhData] = useState<IWFHData>(defaultWfhData());
    const weekDaysToShow = useMemo(() => getWeekDays(wfhData?.start_date?.format("YYYY-MM-DD"), wfhData?.end_date?.format("YYYY-MM-DD")), [wfhData.start_date, wfhData.end_date]);
    const [isWfhDrawerOpen, setIsWfhDrawerOpen] = useState(false);

    const structureData = useCallback((data: any) => {
        if (!data?.wfh_history_requests) return [];
        setWfhHistoryDetails(data.wfh_history_requests)
        return getSortedAndFilteredData(data?.wfh_history_requests, filterParams, sort.keyToSort);
    }, []);


    function getFilterState (filterParams: any){
        let filterValue = [true, false, null];

        switch (filterParams.status) {
            case 'approved':
                filterValue = [true];
                break;
            case 'pending':
                filterValue = [null]
                break;
            case 'rejected':
                filterValue = [false]
                break;
        }
        return filterValue;
    }


    
    const cancelWfhApply = () => {
        setIsWfhDrawerOpen(false);
        setWfhData(defaultWfhData());
    }


    const documentUpload = async (document: any) => {
        if (!wfhData.document) {
            alert('Please select a file first');
            return;
        }

        const formData = new FormData();
        formData.append('file', document);

        const timestamp = moment().format('YYYYMMDDHHmmss');
        const split_filename = wfhData.document.name.split('.');
        const structure_filename = split_filename[0].trim().replace(/[^a-zA-Z]/g, '') + '.' + split_filename[1];
        const documentName = `${timestamp}_${structure_filename}`;

        formData.append('document', documentName);

        const updateResponse = await updateData('CREATE_WFH_REQUEST_DOCUMENT_UPLOAD', formData)
        return updateResponse;
    };


    const createWfhRequest = async () => {
        const from_date = moment(wfhData.start_date).format('YYYY-MM-DD');
        const to_date = moment(wfhData.end_date).format('YYYY-MM-DD');

        let obj = {
            from_date, to_date, name, email_id,
            employee_code: biometric_emp_code,
            reason: wfhData.reason?.trim(),
            wfh_days: wfhData.days
        }

        setWfhData(defaultWfhData());
        setIsWfhDrawerOpen(false)

        if (wfhData.document) {
            const updateResponse: any = await documentUpload(wfhData.document)

            if (updateResponse && updateResponse['msg_id'] === 1) {
                obj['document'] = updateResponse['file_path'];
                setFileList([]);
            }
        }

        await updateData('CREATE_WFH_REQUEST_DATA_UPLOAD', obj)
        setWfhData(defaultWfhData())
        await fetchData('GET_WFH_REQUEST_HISTORY', { employee_code: biometric_emp_code });
    }


    function getSortedAndFilteredData (wfhHistoryRequests: any [], filterParams: IFilterParams, keyToSort: string []) {
        let updatedData  = applyFilter(wfhHistoryRequests, {column: 'is_approved', filterValue: getFilterState(filterParams)})
        updatedData = sortByMultipleFields(updatedData, sort);
        return updatedData;
    }


    const calcelFilter = () => {
        setIsFilterDrawerOpen(false);
        setFilterParams({ ...defaultFilterParams(), searchText: filterParams.searchText });
        setTempFilterParams({ ...defaultFilterParams(), searchText: filterParams.searchText });


        setTableData(getSortedAndFilteredData(wfhHistoryDetails, defaultFilterParams(), sort.keyToSort));
    }

    const submitFilter = () => {
        setIsFilterDrawerOpen(false);
        setFilterParams({ ...tempFilterParams, searchText: filterParams.searchText });
        setTableData(getSortedAndFilteredData(wfhHistoryDetails, tempFilterParams, sort.keyToSort));
    };


    const handleDeleteClick = (selected: any) => {
        setSelectedToDelete({ids: [selected?.id]});
        setShowDeleteModel(true);
    }
    
    const handleDeleteRequest = async () => {
        setShowDeleteModel(false);
        
        await updateData('DELETE_WFH_REQUEST_DATA', selectedToDelete);
        setSelectedToDelete(null);
        await fetchData('GET_WFH_REQUEST_HISTORY', { employee_code: biometric_emp_code });
    }

    const cancelDeleteRequest = () => {
        setShowDeleteModel(false);
        setSelectedToDelete(null);
    }


    useEffect(() => {
        const wfhHistoryDetails = structureData(data);
        setTableData(wfhHistoryDetails);
    }, [data, structureData]);

    useEffect(() => {
        fetchData('GET_WFH_REQUEST_HISTORY', { employee_code: biometric_emp_code })
    }, [biometric_emp_code, fetchData]);



    return (
        <React.Fragment>
            {
                isError ? (
                    <React.Fragment>
                        <h2>{isError.code} Error</h2>
                        <div className="errorContent">
                            <div className="errorContentInner">
                                <span className="error-img"></span>
                                <div>
                                    <h3>{isError.message}</h3>
                                    <p>{Constants.BACK_TO_PREVIOUS_PAGE}</p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <Layout className="mainInnerLayout wfhHistoryList">
                        <div className="innerHeader" style={{ paddingTop: '20px', marginBottom: 0 }}>
                            <div className="ant-row alignItemsCenter">
                                <div className="ant-col ant-col-xl-24 flx justifyContentBetween alignItemsCenter">
                                    <h3>{Constants.WFH_HISTORY}</h3>
                                    <div className='flx ant-col ant-col-xl-8' style={{ justifyContent: 'flex-end', gap: '1rem' }}>
                                        {wfhData.id &&
                                            <WfhDrawer
                                            cancelWfhApply={cancelWfhApply}
                                            wfhData={wfhData}
                                            setWfhData={setWfhData}
                                            isWfhOpen={isWfhDrawerOpen}
                                            setIsWfhOpen={setIsWfhDrawerOpen}
                                            submitWfhData={createWfhRequest}
                                            weekDaysToShow={weekDaysToShow}
                                            fileList={fileList} 
                                            setFileList={setFileList} 
                                            />                  
                                        }

                                        <CommonDrawer
                                        disabled={isLoading}
                                        title={Constants.HISTORY_WFH_FILTER_DRAWER}
                                        drawerInputs={drawerInputs}
                                        filterData={tempFilterParams}
                                        setFilterData={setTempFilterParams}
                                        cancelFilter={calcelFilter}
                                        submitFilter={submitFilter}
                                        isFilterOpen={isFilterDrawerOpen}
                                        setIsFilterOpen={setIsFilterDrawerOpen}
                                        defaultFilterParams={() => filterParams}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <LoadingSpinner isLoading={isLoading}>
                                <div className="customTbl userManagementTbl scroll-y tblStickyTHead main-table" style={{ marginTop: '10px' }}>
                                    <table className="main-table">
                                        <thead>
                                            <tr>
                                                {
                                                    header.map((elem, index) => {

                                                        return (
                                                            <th
                                                                key={elem}
                                                                style={{ ...styles[elem] }}
                                                                className={'text-left'}
                                                            >
                                                                {elem === 'delete' ? "" : elem.split('_').join(" ")}
                                                            </th>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                tableData?.map((emp: any) => {
                                                    return (
                                                        <tr key={emp.name + emp?.reason + emp?.from_date + emp?.to_date}>
                                                            {
                                                                header.map((key, index: number) => {
                                                                    let dataToShow = emp[key.toLowerCase()] || '-';
                                                                    let textColorClass = 'text-black';


                                                                    if (key === 'from_date' || key === 'to_date') {
                                                                        dataToShow = moment(emp[key.toLowerCase()]).format('MMMM D, YYYY')
                                                                    }

                                                                    if (key === 'status') {
                                                                        dataToShow = wfhStatusObj[emp['is_approved']]?.text;
                                                                        textColorClass = wfhStatusObj[emp['is_approved']]?.class;
                                                                    }
                                                                    if (key === 'days') {
                                                                        dataToShow = getWeekdayString(emp['days'])
                                                                    }
                                                                    if (key === 'delete') {
                                                                        dataToShow = emp['is_approved'] === null ? 
                                                                                        <i className='da icon-cross-circle' onClick={() => handleDeleteClick(emp)}></i>
                                                                                    :
                                                                                        ''
                                                                    }

                                                                    return (
                                                                        <td key={key} className={`${key === 'delete' ? 'text-center' : 'text-left'} ${textColorClass}`} style={{ width: '20px' }}>{dataToShow} </td>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                })

                                            }
                                            <Modal
                                                closable={false}
                                                width={350}
                                                title={false}
                                                footer={false}
                                                visible={showDeleteModel}
                                                className="confirmModal"
                                            >
                                                <div className="modalHeader modalHeaderCenter">
                                                    {Constants.UPDATE_ONBOARDING_STATUS_MESSAGE_OPEN}{" "}
                                                    delete the work from home request?
                                                </div>
                                                <div className="modalCustomFooter">
                                                    <Button
                                                        className="linkBtn linkBtn"
                                                        onClick={cancelDeleteRequest}
                                                        disabled={isLoading}
                                                    >
                                                        {Constants.CANCEL}
                                                    </Button>
                                                    <Button
                                                        className="btnOk"
                                                        disabled={isLoading}
                                                        onClick={handleDeleteRequest}
                                                    >
                                                        {Constants.OK}
                                                    </Button>
                                                </div>
                                            </Modal>
                                            {(!tableData || tableData.length === 0) && <NoDataFound />}
                                        </tbody>
                                    </table>
                                </div>
                            </LoadingSpinner>
                        </div>
                    </Layout>
                )
            }

        </React.Fragment>
    )
}

export default HistoryRequestesList;