/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Select, Radio, DatePicker, Button, message, Modal } from "antd";
import { useRbac } from "features/rbac/rbac";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { JoinersListOnboardingService } from "services";
import Designation from "./AddDesignation";
import HiringSource from "./AddHiringSource";

import { Upload } from 'antd';
import { Image, UploadFile, UploadProps } from 'antd';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { Constants } from "views/constants";

import closeIcon from "../../assets/images/close-icon.svg"
import JoineeECardModal from "./JoineeECardModal";
import ImgCrop from "antd-img-crop";
import ToastComponent from "utils/commonFunction";
import { RcFile } from "antd/lib/upload";

const { Option } = Select;

const JoineeForm2: React.FC<{
  modalTitle: string;
  filterParams: any;
  dropdownData: any;
  editUserInfoCancel: any;
  form: any;
  setForm: Function;
  setIsLoading: Function;
  setError: Function;
  getJoinersList: Function;
  signOutWithMessage: Function;
  setDropdownData: Function;
  maxImgSize: number | null
}> = ({
  modalTitle,
  filterParams,
  dropdownData,
  editUserInfoCancel,
  form,
  setForm,
  setIsLoading,
  setError,
  getJoinersList,
  signOutWithMessage,
  setDropdownData,
  maxImgSize
}) => {

    const { hasUserRoles, getUserRole } = useRbac();

    const [officeMailExists, setOfficeMailExists] = useState<boolean>(false);
    const [timeoutId, setTimeoutId] = useState<any>(null);

    const [customHiring, setCustomHiring] = useState<boolean>(false);

    const [customDesignation, setCustomDesignation] = useState<boolean>(false);

    const [selectedFile, setSelectedFile] = useState<File | undefined | null>(null);
    const [fileNameWithTimestamp, setFileNameWithTimestamp] = useState<string>('');


    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState('');
    const [proFileImageUrl, setProfileImageUrl] = useState(null)
    const [isImageRemove, setIsImageRemove] = useState(false);
    const [aboutCandidate, setAboutCandidate] = useState<string>('');
    const [isImageProcessedFinished, setIsImageProcessedFinished] = useState(true);
    const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(false);
    const [url, setUrl] = useState<string>('http://cdn.mcauto-images-production.sendgrid.net/30bf69ebb8191190/8f219f93-1929-430a-ae4c-29c082325ade/300x450.png');
    const [imageCanBeUploaded, setImageCanBeUploaded] = useState<boolean>(false);
    const [errmsgContent, setErrMsgContent] = useState("");
    const [isProcessImgChecked, setIsProcessImgChecked] = useState<boolean>(true);
    const [isIpRemoveChecked, setIsIpRemoveChecked] = useState<boolean>(false);
    const [isZohoValultChecked, setIsZohoValultChecked] = useState<boolean>(false);
    const [isSendEcardChecked, setIsSendEcardChecked] = useState<boolean>(true);
    const maxLength = 500;
    const MAX_FILE_SIZE_BYTES = (maxImgSize || 2) * 1024 * 1024;



    const capitalize = (str: string) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    function resetForm() {
      setSelectedFile(null);


      setProfileImageUrl(null);
      setFileList([])
      setUrl('http://cdn.mcauto-images-production.sendgrid.net/30bf69ebb8191190/8f219f93-1929-430a-ae4c-29c082325ade/300x450.png')
      editUserInfoCancel();
      setCustomHiring(false);
      setCustomDesignation(false);
      setIsProcessImgChecked(true);
      setOfficeMailExists(false);
    }


    async function checkEmail(value: any, joinee_id?: number) {
      const checkEmailLoadingKey = "checkEmail";
      try {
        ToastComponent('loading', checkEmailLoadingKey, 'Checking for availability of gmail...')
        const response: any =
          await new JoinersListOnboardingService().getEmailExistance({
            email_id: value,
            joinee_id: joinee_id
          });
        response.data.message ?
          ToastComponent('error', checkEmailLoadingKey, "Email already exists!")
          :
          ToastComponent('success', checkEmailLoadingKey, 'Email is available!')

        setOfficeMailExists(response.data.message ? true : false);
        setError(null);
      } catch (error: any) {
        ToastComponent('error', checkEmailLoadingKey, "Error in checking email!")
        setError({
          ...error,
          refreshAction: checkEmail,
        });
        if (error.code === 401) {
          signOutWithMessage("You are not authorized");
        }
      }
    }


    async function handleNewSource(value: string, id: string) {
      const saveSourceLoadingKey = "saveSource";
      if (value.trim().length === 0) {
        ToastComponent('error', saveSourceLoadingKey, "Please Enter Valid Source!")
      } else {
        const payload = { source: value.trim() }

        try {
          ToastComponent('loading', saveSourceLoadingKey, "Saving new source...")
          const response: any =
            await new JoinersListOnboardingService().saveNewSource(payload);
          if (response.data.msg_id === 1) {
            ToastComponent('success', saveSourceLoadingKey, "New source saved successfully!")
            setForm((prevForm: any) => {
              return {
                ...prevForm,
                [id]: response.data.data,
              };
            });
            setDropdownData((prev: any) => {
              prev.candidate_info = [...prev.candidate_info, { "label": value.trim(), "value": response.data.data }];
              return prev;
            })
          } else {
            ToastComponent('error', saveSourceLoadingKey, "Source already exists!")
            setCustomHiring(false);
          }

        } catch (error: any) {
          ToastComponent('error', saveSourceLoadingKey, "New source not saved!")
          setError({
            ...error,
            refreshAction: saveSourceLoadingKey,
          });
          if (error.code === 401) {
            signOutWithMessage("You are not authorized");
          }
        }
      }
    }


    async function handleNewDesignation(value: string, id: string) {
      const saveDesignationLoadingKey = "saveDesignation";
      const payload = { designation: value.trim() }

      // Implemenent axios request to save new source
      try {
        ToastComponent('loading', saveDesignationLoadingKey, "Saving new designation...")
        const response: any =
          await new JoinersListOnboardingService().saveNewDesignation(payload);
        if (response.data.status === 0) {
          ToastComponent('success', saveDesignationLoadingKey, "New Designation saved successfully!")
          setForm((prevForm: any) => {
            return {
              ...prevForm,
              [id]: response.data.data,
            };
          });
          setDropdownData((prev: any) => {
            prev.designation_info = [...prev.designation_info, { "label": value, "value": response.data.data }];
            return prev;
          })
        } else {
          ToastComponent('error', saveDesignationLoadingKey, "New source not saved!")
          setCustomDesignation(false);
        }

      } catch (error: any) {
        ToastComponent('error', saveDesignationLoadingKey, "New source not saved!")
        setError({
          ...error,
          refreshAction: saveDesignationLoadingKey,
        });
        if (error.code === 401) {
          signOutWithMessage("You are not authorized");
        }
      }
    }


    function handleChangeSelect(value: string, id: string) {
      setForm((prevForm: any) => {
        return {
          ...prevForm,
          [id]: value,
        };
      });
    }


    function handleChangeTextArea(e: any) {
      const { value } = e.target;
      setAboutCandidate(value);
      setForm((prevForm: any) => {
        return {
          ...prevForm,
          about_candidate: value,
        };
      });
    }

    function handleChangeSendEcardCheckBox(e: any) {
      const { checked } = e.target;
      setIsSendEcardChecked(checked)
      setForm((prevForm: any) => {
        return {
          ...prevForm,
          send_welcome_ecard: checked,
        };
      });
    }

    function handleChangeIpRemoveCheckBox(e: any) {
      const { checked } = e.target;
      setIsIpRemoveChecked(checked)
      setForm((prevForm: any) => {
        return {
          ...prevForm,
          remove_ip_restrict: checked,
        };
      });
    }
    function handleChangeZohoVaultInviteCheckBox(e: any) {
      const { checked } = e.target;
      setIsZohoValultChecked(checked)
      setForm((prevForm: any) => {
        return {
          ...prevForm,
          invite_to_zoho_vault: checked,
        };
      });
    }


    function handleDateChange(date: any, id: string) {
      setForm((prevForm: any) => {
        return {
          ...prevForm,
          [id]: date,
        };
      });
    }


    function handleChange(e: any) {
      const { id, value, type } = e.target;

      if (type === "radio") {
        return setForm((prevForm: any) => {
          return {
            ...prevForm,
            working_mode: value,
          };
        });
      }

      if (id === "office_email") {
        setForm((prevForm: any) => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }

          setTimeoutId(setTimeout(() => checkEmail(value), 1200));

          return {
            ...prevForm,
            office_email: value,
          };
        });
      } else {
        setForm((prevForm: any) => {
          return {
            ...prevForm,
            [id]: value,
          };
        });
      }
      if (
        (id === "first_name" || id === "last_name") &&
        modalTitle.startsWith("Add")
      ) {
        setForm((prevForm: any) => {
          const email = `${prevForm.first_name}${prevForm.last_name ? "." + prevForm.last_name : ""
            }@digitalavenues.com`.toLocaleLowerCase();

          if (timeoutId) {
            clearTimeout(timeoutId);
          }

          setTimeoutId(setTimeout(() => checkEmail(email), 1200));

          return {
            ...prevForm,
            office_email: email,
          };
        });
      }
    }


    const beforeCropping = (file: RcFile) => {
      if (file && file.size > MAX_FILE_SIZE_BYTES) {
        setImageCanBeUploaded(false);
        setErrMsgContent(`File size exceeds ${maxImgSize || 2}MB limit.`);
        return false
      }
      else if (!isProcessImgChecked && file.type !== 'image/png') {
        setImageCanBeUploaded(false);
        setErrMsgContent('Image Should be in PNG format')
        return false;
      }
      setImageCanBeUploaded(true);
      setErrMsgContent("")
    }


    const handleImageChange: UploadProps['onChange'] = async ({ fileList: newFileList }) => {

      if (!imageCanBeUploaded) {
        message.error({
          content: errmsgContent,
          duration: 5,
          className: "toastMsg savedError",
        });
        setFileList([]);
        return;
      }

      const processProfilePic = "processprofilePic";
      const prevFileList = [...fileList];
      let updatedFileList: UploadFile<any>[]

      if (newFileList.length > 0) {
        updatedFileList = [{
          uid: newFileList[0].uid,
          name: newFileList[0]?.name,
          status: 'uploading',
          thumbUrl: newFileList[0]?.thumbUrl,
          lastModified: newFileList[0]?.lastModified,
          originFileObj: newFileList[0]?.originFileObj,
          url: newFileList[0].url,
          size: newFileList[0].size
        }]
      } else {
        updatedFileList = []
      }

      setFileList(updatedFileList)

      if (!newFileList[0]?.originFileObj ||
        !newFileList[0]?.size ||
        !prevFileList[0]?.originFileObj ||
        !prevFileList[0]?.size
      ) {
        return;
      }

      if (newFileList.length === 0 ||
        prevFileList.length === 0
      ) {
        return;
      }


      const file = newFileList[0].size >= prevFileList[0].size ? prevFileList[0].originFileObj : newFileList[0].originFileObj
      const formData = new FormData();
      formData.append('image', file);

      if (isProcessImgChecked) {

        try {

          message.loading({
            content: "Processing Profile Image...",
            duration: 0,
            key: processProfilePic,
            className: "toastMsg loadingMsg",
          });

          setIsImageProcessedFinished(false)

          const response: any = await new JoinersListOnboardingService().processProfilePicByGcloud(formData);


          if (response.status === 200) {

            message.success({
              content: "Profile Image processed successfully!",
              key: processProfilePic,
              duration: 5,
              className: "toastMsg savedSuccess",
            });

            const timestamp = moment().format('YYYYMMDDHHmmss');
            const structurFileName = file.name.split('.')[0].trim().replace(/[^a-zA-Z]/g, '');
            const file_name = `${structurFileName}.png`;

            const fileName = `${timestamp}_${file_name}`;

            setFileNameWithTimestamp(fileName);

            setForm((prevForm: any) => ({
              ...prevForm,
              profile_image: fileName,
            }));


            const processedFile = new File([response.data], fileName, { type: 'image/png' });

            setSelectedFile(processedFile);

            const imageUrl = URL.createObjectURL(processedFile);
            setUrl(imageUrl);

            const updatedFileList: UploadFile<any>[] = [
              {
                uid: '-1',
                type: processedFile.type,
                size: processedFile.size,
                lastModified: processedFile.lastModified,
                lastModifiedDate: processedFile['lastModifiedDate'],
                name: fileName,
                url: imageUrl
              },
            ];

            setIsImageProcessedFinished(true);
            setFileList(updatedFileList)

          } else {
            message.error({
              content: "Profile Image not Processed!",
              key: processProfilePic,
              duration: 5,
              className: "toastMsg savedError",
            });
          }
        } catch (error: any) {
          message.error({
            content: "Profile Image not Processed!",
            key: processProfilePic,
            duration: 5,
            className: "toastMsg savedError",
          });
          setError({
            ...error,
            refreshAction: processProfilePic,
          });
          if (error.code === 401) {
            signOutWithMessage("You are not authorized");
          }
        }
      } else {

        setFileList(newFileList)
        const timestamp = moment().format('YYYYMMDDHHmmss');
        const structurFileName = file.name.split('.')[0].trim().replace(/[^a-zA-Z]/g, '');
        const file_name = `${structurFileName}.png`;

        const fileName = `${timestamp}_${file_name}`;

        setFileNameWithTimestamp(fileName);

        setForm((prevForm: any) => ({
          ...prevForm,
          profile_image: fileName,
        }));

        setSelectedFile(newFileList[0].originFileObj);

        const imageUrl = URL.createObjectURL(newFileList[0].originFileObj);
        setUrl(imageUrl);

      }
    }

    

    const dummyRequest = (req: UploadRequestOption<any>) => {
      const { onSuccess } = req;
      setTimeout(() => {
        if (onSuccess)
          onSuccess("ok");
      }, 0);
    };


    const handlePreview = async (file: UploadFile) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as File);
      }

      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
    };


    const handleUpload = async () => {
      if (!selectedFile) {
        alert('Please select a file first');
        return;
      }
      const uploadProfileImagLoadingKey = "uploadProfileImage";

      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('filename', fileNameWithTimestamp);


      try {
        message.loading({
          content: "Uploading Profile Image...",
          duration: 0,
          key: uploadProfileImagLoadingKey,
          className: "toastMsg loadingMsg",
        });
        const response: any =
          await new JoinersListOnboardingService().UploadImageToCloud(formData)

        if (response.data.msg_id === 1) {
          message.success({
            content: "Profile Image Uploaded successfully!",
            key: uploadProfileImagLoadingKey,
            duration: 5,
            className: "toastMsg savedSuccess",
          });

        } else {
          message.error({
            content: "Profile Image not Uploaded!",
            key: uploadProfileImagLoadingKey,
            duration: 5,
            className: "toastMsg savedError",
          });

        }

      } catch (error: any) {
        message.error({
          content: "Profile Image not Uploaded!",
          key: uploadProfileImagLoadingKey,
          duration: 5,
          className: "toastMsg savedError",
        });
        setError({
          ...error,
          refreshAction: uploadProfileImagLoadingKey,
        });
        if (error.code === 401) {
          signOutWithMessage("You are not authorized");
        }
      }
    };


    const getBase64 = (file: File): Promise<string> =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });

    const handleCancel = () => {
      setSelectedFile(null);
      setUrl('http://cdn.mcauto-images-production.sendgrid.net/30bf69ebb8191190/8f219f93-1929-430a-ae4c-29c082325ade/300x450.png');
      setFileList([])
      setProfileImageUrl(null);


      setForm((prevForm: any) => {
        return {
          ...prevForm,
          profile_image: null,
          profile_image_url: null
        };
      });
    };

    const handleRemove = () => {
      handleCancel();
      setIsImageRemove(false);
    };


    const saveJoineeInfo = async () => {
      const saveJoineeLoadingKey = "saveJoinee";
      try {
        message.loading({
          content: "Saving new member info...",
          duration: 0,
          key: saveJoineeLoadingKey,
          className: "toastMsg loadingMsg",
        });
        const response: any =
          await new JoinersListOnboardingService().saveNewJoineeInfo(form);
        if (response.data.msg_id === -1) {
          return message.error({
            content: "New member info not saved!",
            key: saveJoineeLoadingKey,
            duration: 5,
            className: "toastMsg savedError",
          });
        }
        message.success({
          content: "New member info saved successfully!",
          key: saveJoineeLoadingKey,
          duration: 5,
          className: "toastMsg savedSuccess",
        });
        setIsLoading(true);
        setIsProcessImgChecked(true);
        await getJoinersList(filterParams);
        setIsLoading(false);
        setError(null);
      } catch (error: any) {
        message.error({
          content: "New member info not saved!",
          key: saveJoineeLoadingKey,
          duration: 5,
          className: "toastMsg savedError",
        });
        setError({
          ...error,
          refreshAction: saveJoineeInfo,
        });
        if (error.code === 401) {
          signOutWithMessage("You are not authorized");
        }
      }
    };


    async function handleSubmit(e: any) {
      e.preventDefault();
      resetForm();
      if (enableSave()) {
        if (selectedFile) {
          await handleUpload();
        }
        await saveJoineeInfo();
      }
    }


    function validPhone(phone: string) {
      if (phone !== "") {
        const validRegex =
          /^[0-9]+$/;

        if (!phone?.trim().match(validRegex)) {
          return false;
        }
      }
      return true;
    }


    function validEmail(email: string) {
      if (email !== "") {
        const validRegex =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!email?.trim().match(validRegex)) {
          return false;
        }
      }
      return true;
    }


    function validLink(link: string) {
      if (link !== "") {
        const validRegex =
          /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:*!%_\+.~#?&/=]*)$/gm;
        if (!link?.trim().match(validRegex)) {
          return false;
        }
      }
      return true;
    }

    function enableSave() {
      if (
        form.first_name?.length > 0 &&
        (form.office_email?.length > 3 && !officeMailExists && validEmail(form.office_email)) &&
        (form.personal_email?.length > 3 && validEmail(form.personal_email)) &&
        isImageProcessedFinished &&
        form.location > 0 &&
        form.designation > 0 &&
        form.department > 0 &&
        form.candidate_source > 0 &&
        form.employee_type > 0 &&
        validPhone(form.mobile_no) &&
        form.working_mode > 0 &&
        form.date_of_joining?.length > 0 &&
        form.appointment_letter_signed_on?.length > 0 &&
        (moment(form.appointment_letter_signed_on) <= moment(form.date_of_joining)) &&
        (form.date_of_onboarding === '' || form.date_of_onboarding === undefined || form.date_of_onboarding === null || moment(form.date_of_onboarding) >= moment(form.date_of_joining)) &&
        (form.date_of_probation === '' || form.date_of_probation === undefined || form.date_of_probation === null || moment(form.date_of_probation) > moment(form.date_of_joining)) &&
        (form.date_of_probation === '' || form.date_of_probation === undefined || form.date_of_probation === null || form.date_of_onboarding === "" || form.date_of_onboarding === null || form.date_of_onboarding === undefined || moment(form.date_of_probation) > moment(form.date_of_onboarding)) &&
        (form.agreement_signed_on === '' || form.agreement_signed_on === undefined || form.agreement_signed_on === null || moment(form.agreement_signed_on) <= moment(form.date_of_joining)) &&
        validLink(form?.welcome_kit ?? "")
      ) {
        return true;
      }
      return false;
    }

    const getDepartmentLabelByValue = (value: any) => {
      const department = dropdownData.department_info.find((dept: any) => dept.value === value);
      return department ? department.label : '';
    };

    const getDesignationLabelByValue = (value: any) => {
      const designation = dropdownData.designation_info.find((desig: any) => desig.value === value);
      return designation ? designation.label : '';
    };

    const dayDiff = (endDate: string) => {
      const end_date = moment(endDate);
      return end_date.diff(moment(), 'days');
    }
    const isOfficeMailEditable = (joinee_id: number, date_of_joining: string, is_confirmed: boolean) => {
      if (joinee_id && is_confirmed) {
        const duration = dayDiff(date_of_joining);
        if(duration >3){
          return false;
        }else{
          return true;
        }
      }
      return false
    }

    function handleChangeOfficeMail(e: any, joinee_id: number){
      const { value } = e.target;
      setForm((prevForm: any) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        setTimeoutId(setTimeout(() => checkEmail(value, joinee_id), 1200));
        return {
          ...prevForm,
          office_email: value,
        };
      });
    }


    useEffect(() => {
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }, [timeoutId]);


    useEffect(() => {

      if (form.profile_image_url) {
        setProfileImageUrl(form.profile_image_url)
        setUrl(form.profile_image_url);

        setFileList([{
          uid: '-1', // unique identifier
          name: form.profile_image, // file name
          status: 'done', // file status: 'done', 'error', 'uploading', 'removed'
          url: form.profile_image_url, // image URL
          thumbUrl: form.profile_image_url
        }]
        )
      }

      if (form.about_candidate) {

        if (form.about_candidate <= maxLength) {
          setAboutCandidate(form.about_candidate);
        } else {
          setAboutCandidate(form.about_candidate.substring(0, maxLength));
        }
      } else {
        setAboutCandidate('')
      }

      if(form.remove_ip_restrict){
        setIsIpRemoveChecked(true)
      }else{
        setIsIpRemoveChecked(false)
      }
      if(form.invite_to_zoho_vault){
        setIsZohoValultChecked(true)
      }else{
        setIsZohoValultChecked(false)
      }

      if(form.send_welcome_ecard){
        setIsSendEcardChecked(true)
      }else{
        setIsSendEcardChecked(false)
      }
    }, [form])


    return (
      <>
        <form onSubmit={handleSubmit} id="form">
          <div className="modalCustomBody">
            <div
              className="editUserInfoForm editEvent modalInnerBg"
              style={{ padding: "15px" }}
            >
              <div className="ant-row">
                <div className="ant-col ant-col-xl-12">
                  <div className="inputGroup">
                    <label>
                      First Name <span className="req">*</span>
                    </label>
                    <input
                      placeholder="First Name"
                      type="text"
                      onChange={handleChange}
                      id="first_name"
                      value={capitalize(form.first_name)}
                      className="ant-input"
                      autoComplete="false"
                    />
                  </div>
                </div>
                <div className="ant-col ant-col-xl-12">
                  <div className="inputGroup">
                    <label>Last Name</label>
                    <input
                      placeholder="Last Name"
                      type="text"
                      onChange={handleChange}
                      id="last_name"
                      value={capitalize(form.last_name)}
                      className="ant-input"
                      autoComplete="false"
                    />
                  </div>
                </div>
              </div>
              <div className="ant-row">
                <div className="ant-col ant-col-xl-12">
                  <div className="inputGroup">
                    <label>
                      Office Email <span className="req">*</span>
                    </label>
                    {modalTitle === "Add New Member Info" ? (
                      <>
                        <input
                          placeholder="Office Email"
                          type="email"
                          onChange={handleChange}
                          id="office_email"
                          value={form.office_email}
                          className="ant-input"
                          autoComplete="false"
                        />
                        {officeMailExists && (
                          <label className="validdationWrap error">
                            ** Office email already exists
                          </label>
                        )}
                        {validEmail(form.office_email) === false && (
                          <label className="validdationWrap error">
                            ** Email not valid
                          </label>
                        )}
                      </>
                    ) : (
                    <>
                      <input
                        placeholder="Office Email"
                        type="email"
                        className="ant-input"
                        id="office_email"
                        value={form.office_email}
                        autoComplete="false"
                        onChange={(e)=>handleChangeOfficeMail(e, form.joinee_id)}
                        disabled={isOfficeMailEditable(form.joinee_id, form.date_of_joining, form.is_confirmed)}
                      />
                      {officeMailExists && (
                          <label className="validdationWrap error">
                            ** Office email already exists
                          </label>
                        )}
                        {validEmail(form.office_email) === false && (
                          <label className="validdationWrap error">
                            ** Email not valid
                          </label>
                        )}
                    </>
                    )}
                  </div>
                </div>
                {/* {img && <img src={img} />} */}
                <div className="ant-col ant-col-xl-12">
                  <div className="inputGroup">
                    <label>
                      Personal Email <span className="req">*</span>
                    </label>
                    <input
                      placeholder="Personal Email"
                      type="email"
                      onChange={handleChange}
                      id="personal_email"
                      value={form.personal_email}
                      autoComplete="false"
                      className="ant-input"
                    />
                    {validEmail(form.personal_email) === false && (
                      <label className="validdationWrap error">
                        ** Email not valid
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className="ant-col ant-col-sm-12 ant-col-xl-8">
                  <div className="inputGroup">
                    <div className="checkboxWrap">
                      <input type="checkbox" id="sendEcard" checked={isSendEcardChecked} onChange={handleChangeSendEcardCheckBox} />
                      <label htmlFor="sendEcard" className="checkboxLabel">
                      Send Welcome E-card
                      </label>
                    </div>
                  </div>
                </div>
                {isSendEcardChecked ? 
              <div className="ant-row">
                <div className="ant-col ant-col-xl-12">
                  <div className="inputGroup">
                    <label>About Candidate </label>
                    <textarea
                      className="about_candidate"
                      onChange={handleChangeTextArea}
                      placeholder="Type something here..."
                      rows={5}
                      cols={50}
                      id="about_candidate"
                      maxLength={maxLength}
                      value={aboutCandidate}
                    />
                    <label>{maxLength - aboutCandidate.length}/{maxLength} characters remaining</label>

                  </div>
                </div>

                <div className="ant-col ant-col-xl-12 uploadColWrap">

                  <div className="inputGroup">

                    {proFileImageUrl ? (
                      <div className="previewThumb">

                        <img src={proFileImageUrl} alt="Profile Image" />
                        <Button className='removeImage' onClick={() => setIsImageRemove(true)}>x</Button>
                      </div>
                    ) : (
                      <div className="uploadProfile">
                        <ImgCrop beforeCrop={beforeCropping} aspectSlider={true}>
                          <Upload

                            listType="picture-card"
                            fileList={fileList}
                            onChange={handleImageChange}
                            maxCount={1}
                            onRemove={handleRemove}
                            onPreview={handlePreview}
                            customRequest={dummyRequest}
                            accept="image/jpeg,image/png,image/webp,imgae/jpg,image/jpge"
                            disabled={!isImageProcessedFinished}
                          >
                            {fileList.length < 1 && 'Upload Image'}
                          </Upload>
                        </ImgCrop>

                      </div>

                    )}

                    {previewImage && (
                      <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => {
                            setPreviewOpen(visible);
                            if (!visible) setPreviewImage('');
                          },
                        }}
                        src={previewImage}
                      />
                    )}

                  </div>

                  <div className="inputGroup processingWrap">
                    <div className="processImgCheckboxWrap">
                      <div className="checkboxWrap">
                        <input type="checkbox" id="processingImage" checked={isProcessImgChecked} onChange={(e) => setIsProcessImgChecked(e.target.checked)} />
                        <label htmlFor="processingImage" className="checkboxLabel">Process Image</label>
                      </div>
                    </div>
                    <Button className="btnOk previewBtn" onClick={() => setIsPreviewVisible(true)}>Preview Card</Button>
                  </div>
                </div>
              </div>: 
              <div></div>}
              <div className="ant-row">
                <div className="ant-col ant-col-xl-12">
                  <div className="inputGroup">
                    <label>
                      Location <span className="req">*</span>
                    </label>
                    <Select
                      placeholder="Select"
                      options={dropdownData?.locations_info}
                      onChange={(value: any) => handleChangeSelect(value, "location")}
                      value={form.location}
                      allowClear
                    ></Select>
                  </div>
                </div>
                <div className="ant-col ant-col-xl-8">
                  <div className="inputGroup">
                    <label>
                      Designation <span className="req">*</span>
                    </label>
                    <Designation
                      customDesignation={customDesignation}
                      designation_info={dropdownData?.designation_info}
                      handleBlur={handleNewDesignation}
                      form={form} setCustomDesignation={setCustomDesignation}
                      handleChangeSelect={handleChangeSelect} />
                  </div>
                </div>
                <div className="ant-col ant-col-xl-8">
                  <div className="inputGroup">
                    <label>
                      Technology/Department <span className="req">*</span>
                    </label>
                    <Select
                      placeholder="Select"
                      onChange={(value: any) => {
                        handleChangeSelect(value, "department")
                      }}
                      value={form.department}
                      allowClear
                    >
                      {dropdownData?.department_info
                        .map((item: any) => (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </div>
                <div className="ant-col ant-col-sm-12 ant-col-xl-8">
                  <div className="inputGroup">
                    <div className="checkboxWrap centerCeckboxVertically">
                      <input type="checkbox" id="ipRemove" checked={isIpRemoveChecked} onChange={handleChangeIpRemoveCheckBox} />
                      <label htmlFor="ipRemove" className="checkboxLabel">
                        Remove IP Restriction
                      </label>
                    </div>
                  </div>
                </div>

                <div className="ant-col ant-col-sm-12 ant-col-xl-8">
                  <div className="inputGroup">
                    <div className="checkboxWrap centerCeckboxVertically">
                      <input type="checkbox" id="zohoValut" checked={isZohoValultChecked} onChange={handleChangeZohoVaultInviteCheckBox} />
                      <label htmlFor="zohoValut" className="checkboxLabel">
                        Invite to Zoho Vault
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ant-row">
                <div className="ant-col ant-col-xl-8">
                  <div className="inputGroup">
                    <label>
                      Candidate Source <span className="req">*</span>
                    </label>
                    <HiringSource customHiring={customHiring} candidate_info={dropdownData?.candidate_info} handleBlur={handleNewSource} form={form} setCustomHiring={setCustomHiring} handleChangeSelect={handleChangeSelect} />
                  </div>
                </div>
                <div className="ant-col ant-col-xl-8">
                  <div className="inputGroup">
                    <label>
                      Employee Type <span className="req">*</span>
                    </label>
                    <Select
                      placeholder="Select"
                      options={dropdownData?.employee_type_info}
                      onChange={(value) =>
                        handleChangeSelect(value, "employee_type")
                      }
                      value={form.employee_type}
                      allowClear
                    ></Select>
                  </div>
                </div>
                <div className="ant-col ant-col-xl-8">
                  <div className="inputGroup">
                    <label>Mobile Number</label>
                    <input
                      placeholder="Mobile Number"
                      type="text"
                      onChange={handleChange}
                      id="mobile_no"
                      value={form.mobile_no}
                      className="ant-input"
                      autoComplete="false"
                      maxLength={18}
                    />
                    {validPhone(form.mobile_no) === false && (
                      <label className="validdationWrap error">
                        **Phone number not valid
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className="ant-row">
                <div className="ant-col ant-col-xl-8">
                  <div className="inputGroup">
                    <label>
                      Date of Joining <span className="req">*</span>
                    </label>
                    <DatePicker
                      onChange={(date, dateString) =>
                        handleDateChange(dateString, "date_of_joining")
                      }
                      value={
                        form.date_of_joining
                          ? moment(form.date_of_joining)
                          : (null as any)
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="ant-col ant-col-xl-8">
                  <div className="inputGroup">
                    <label>
                      Date of Onboarding
                    </label>
                    <DatePicker
                      onChange={(date, dateString) =>
                        handleDateChange(dateString, "date_of_onboarding")
                      }
                      value={
                        form.date_of_onboarding
                          ? moment(form.date_of_onboarding)
                          : (null as any)
                      }
                      style={{ width: "100%" }}
                    />
                    {Boolean(
                      ((form.date_of_joining && form.date_of_onboarding && moment(form.date_of_onboarding) < moment(form.date_of_joining)))) && (
                        <label className="validdationWrap error">
                          ** Date of Onboarding should be greater than or equal to Date
                          of Joining
                        </label>
                      )}
                  </div>
                </div>
                <div className="ant-col ant-col-xl-8">
                  <div className="inputGroup">
                    <label>
                      Date of Probation
                    </label>
                    <DatePicker
                      onChange={(date, dateString) =>
                        handleDateChange(dateString, "date_of_probation")
                      }
                      value={
                        form.date_of_probation
                          ? moment(form.date_of_probation)
                          : (null as any)
                      }
                      style={{ width: "100%" }}
                    />
                    {Boolean(
                      ((form.date_of_joining && form.date_of_probation && moment(form.date_of_probation) <= moment(form.date_of_joining))
                      ) || ((form.date_of_onboarding && form.date_of_probation && moment(form.date_of_probation) <= moment(form.date_of_onboarding))
                      )) && (
                        <label className="validdationWrap error">
                          ** Date of probation should be greater than Date
                          of Joining and Date of Onboarding
                        </label>
                      )}
                  </div>
                </div>

                <div className="ant-col ant-col-xl-8">
                  <div className="inputGroup">
                    <label>Confidential Agreement Signed on</label>
                    <DatePicker
                      onChange={(date, dateString) =>
                        handleDateChange(dateString, "agreement_signed_on")
                      }
                      value={
                        form.agreement_signed_on
                          ? moment(form.agreement_signed_on)
                          : (null as any)
                      }
                      style={{ width: "100%" }}
                    />
                    {Boolean(
                      ((form.date_of_joining && form.agreement_signed_on && moment(form.agreement_signed_on) > moment(form.date_of_joining))
                      )) && (
                        <label className="validdationWrap error">
                          ** Confidential Agreement Signed on should be less than or equal to Date
                          of Joining
                        </label>
                      )}
                  </div>
                </div>
                <div className="ant-col ant-col-xl-8">
                  <div className="inputGroup">
                    <label>
                      Appointment Letter Signed on <span className="req">*</span>
                    </label>
                    <DatePicker
                      onChange={(date, dateString) =>
                        handleDateChange(dateString, "appointment_letter_signed_on")
                      }
                      value={
                        form.appointment_letter_signed_on
                          ? moment(form.appointment_letter_signed_on)
                          : (null as any)
                      }
                      style={{ width: "100%" }}
                    />
                    {Boolean(
                      form.date_of_joining &&
                      (form.appointment_letter_signed_on > form.date_of_joining)
                    ) && (
                        <label className="validdationWrap error">
                          ** Appointment Letter Signed on should be less than or equal
                          to Date of Joining
                        </label>
                      )}
                  </div>
                </div>
              </div>

              <div className="ant-row">
                <div className="ant-col ant-col-xl-24">
                  <div className="inputGroup">
                    <label>Welcome Kit</label>
                    <input
                      placeholder="Welcome Kit"
                      type="text"
                      onChange={handleChange}
                      id="welcome_kit"
                      value={form.welcome_kit}
                      className="ant-input"
                      autoComplete="false"
                    />

                    {Boolean(
                      !validLink(form?.welcome_kit ?? "")
                    ) && (
                        <label className="validdationWrap error">
                          ** Not a valid link
                        </label>
                      )}
                  </div>
                </div>
              </div>

              <div className="ant-row">
                {!modalTitle.startsWith("Add") &&
                  form?.is_confirmed !== null &&
                  form?.onboarding_status !== true && (
                    <div className="ant-col ant-col-xl-12">
                      <div className="inputGroup">
                        <label>Joining Status</label>
                        <Select
                          placeholder="Select"
                          options={[
                            { label: "Joining", value: true },
                            { label: "Not Joining", value: false },
                          ]}
                          onChange={(value) =>
                            handleChangeSelect(value, "is_confirmed")
                          }
                          value={form.is_confirmed}
                          allowClear
                        ></Select>
                      </div>
                    </div>
                  )}
                <div className="ant-col ant-col-xl-12">
                  <div className="inputGroup">
                    <label>
                      Working Mode <span className="req">*</span>
                    </label>
                    <Radio.Group
                      onChange={handleChange}
                      value={form.working_mode}
                      className="radioGroupWrap"
                      style={{ position: "relative", top: "8px" }}
                    >
                      {dropdownData?.working_mode_info?.map((each: any) => (
                        <Radio key={each.value} value={each.value}>
                          {each.label}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "15px" }}></div>
            {hasUserRoles([2]) && getUserRole()?.role_alias === "HOps" && (
              <div
                className="editUserInfoForm editEvent modalInnerBg"
                style={{ padding: "15px" }}
              >
                <div className="ant-row">
                  <div className="ant-col ant-col-xl-12">
                    <div className="inputGroup">
                      <label>Reporting Manager</label>
                      <Select
                        placeholder="Select"
                        options={dropdownData?.reporting_manager_info}
                        onChange={(value) =>
                          handleChangeSelect(value, "reporting_manager")
                        }
                        value={form.reporting_manager}
                        showSearch
                        filterOption={(input, option: any) =>
                          option?.label
                            ?.toLowerCase()
                            .includes(input?.toLowerCase())
                            ? true
                            : false
                        }
                        allowClear
                      ></Select>
                    </div>
                  </div>
                  <div className="ant-col ant-col-xl-12">
                    <div className="inputGroup">
                      <label>Assigned Project</label>
                      <input
                        placeholder="Assigned Project"
                        type="text"
                        className="ant-input"
                        onChange={handleChange}
                        id="assigned_project"
                        value={form.assigned_project ?? ""}
                        autoComplete="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="modalCustomFooter">
            <div className="ant-row">
              <div className="ant-col ant-col-xl-24">
                <Button className="linkBtn" onClick={resetForm}>
                  CLOSE
                </Button>
                <button
                  type="submit"
                  className="ant-btn btnOk"
                  disabled={!enableSave()}

                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </form>




        <Modal
          closable={false}
          width={350}
          title={false}
          footer={false}
          visible={isImageRemove}
          className="confirmModal"
        >
          <div className="modalHeader modalHeaderCenter">

            Are you sure to <span style={{ color: "red" }}>Delete</span>{" "} {form.first_name}'s profile picture?

          </div>
          <div className="modalCustomFooter">
            <Button
              className="linkBtn linkBtn"
              onClick={() => {
                setIsImageRemove(false);
              }}
            >
              {Constants.CANCEL}
            </Button>
            <Button
              className="btnOk"
              onClick={() => {
                setUrl('http://cdn.mcauto-images-production.sendgrid.net/30bf69ebb8191190/8f219f93-1929-430a-ae4c-29c082325ade/300x450.png');
                handleRemove()

              }}
            >
              {Constants.OK}
            </Button>
          </div>
        </Modal>


        <Modal
          closable={false}
          width={350}
          title={false}
          footer={false}
          visible={isPreviewVisible}
          className="confirmModal previewCard"
        >
          <JoineeECardModal
            designation={getDesignationLabelByValue(form.designation)}
            setIsPreviewVisible={setIsPreviewVisible}
            closeIcon={closeIcon}
            form={form}
            department={getDepartmentLabelByValue(form.department)}
            url={url} />
        </Modal>

      </>
    );
  };

export default JoineeForm2;
