import { useState, useEffect } from 'react';
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import { RecruiteBoardTicketDuration } from "services"
import { auth } from "utils/auth/google.security";
import { getEncodedData } from "utils/commonFunction";
import { useHistory } from 'react-router';
import { IFilterData, IFilterDrawerInputProps, RecruitDateRange, filter } from 'models/interfaces';
import RecruitmentDrawer from 'utils/commonFilter/filterDrawer/RecruitmentDrawer';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { Constants } from "../constants";
import DateRangePicker from 'views/Reports/DateRangePicker';
import { RangeValue } from 'models/types/Reports/DateRangeSelector';
import type { Moment } from 'moment';
import moment from 'moment';
import { usePageTitle } from 'hooks';
import ExcelJS from 'exceljs';
import NoDataFound from 'views/NoDataFound';


const handleDatePicker = (): filter => {
    const date = new Date();
    if (date.getDate() === 1) {
        return {
            startDay: new Date(date.getFullYear(), date.getMonth()).getDate(),
            endDay: new Date(date.getFullYear(), date.getMonth(), 0).getDate(),
            month: date.getMonth(),
            year: date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear()
        }
    }
    return {
        startDay: new Date(date.getFullYear(), date.getMonth() + 1).getDate(),
        endDay: date.getDate() - 1,
        month: date.getMonth() + 1,
        year: date.getFullYear()
    }
}

const drawerInputs: IFilterDrawerInputProps[] = [

    {
        type: "multiSelect",
        label: "Show Columns",
        keyName: "Show Columns",
        options: [{ 'label': 'Ticket Id', 'value': 'ticket_id' }, { 'label': 'Name', 'value': 'name' }, { 'label': 'Position', 'value': 'position' }, { 'label': 'Description', 'value': 'description' }, { 'label': 'Screening', 'value': 'screening' }, { 'label': 'Interviewing', 'value': 'interviewing' },
        { 'label': 'Machine Test', 'value': 'machine_test' }, { 'label': 'Hr Round', 'value': 'hr_round' }],

        show: true,

    },
    {
        type: "singleSelect",
        label: "Filtered Columns",
        keyName: "Filtered Columns",
        options: [{ 'label': 'Screening', 'value': 'screening' }, { 'label': 'Interviewing', 'value': 'interviewing' }, { 'label': 'Machine Test', 'value': 'machine_test' }, { 'label': 'Hr Round', 'value': 'hr_round' }],

        show: true,
    },
    {
        type: "text",
        label: "Minimum Days",
        keyName: "Days",

    }

]

const keyToClassMap: { [key: string]: string } = {
    'ticket_id': 'colLeftSticky text-left fontMedium',
    'name': 'colLeftSticky text-left fontMedium',
    'position': 'text-left',
    'description': 'text-left',
    'screening': 'text-center',
    'interviewing': 'text-center',
    'machine_test': 'text-center',
    'hr_round': 'text-center'
};


const RecruitmentBoard = () => {

    usePageTitle(Constants.RECRUITMENT_TITLE);

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [isTableLoading, setIsTableLoading] = useState(false);

    const [ticketsData, setTicketsData] = useState<any>([]);
    const [recruitmentBoardTicketURL, setRecruitmentBoardTicketURL] = useState<string>('');

    const [error, setError] = useState<any>(null);

    const [selectedOptions, setSelectedOptions] = useState<string[]>();
    const [columns, setColumns] = useState<string[]>([]);

    const [filteredCol, setFilteredCol] = useState<string | null>(null);
    const [enteredNumber, setEnteredNumber] = useState<number>(0);

    const [filteredData, setFilteredData] = useState<any[]>([]); // State to store filtered data
    const [header, setHeader] = useState<string[]>([
        'ticket_id',
        'name',
        'position',
        'description',
        'screening',
        'interviewing',
        'machine_test',
        'hr_round'
    ])

    const [selectedRange, setSelectedRange] = useState<RangeValue>(null);
    const [dateFilter, setDateFilter] = useState<filter>(handleDatePicker());

    const [startDate, setStartDate] = useState<string>(moment().subtract(30, 'days').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DD'))

    const [sort, setSort] = useState<{ keyToSort: string, direction: string }>({ keyToSort: "", direction: "desc" });

    const [open, setOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState<any>([]);
    const [currentChangedKey, setCurrentChangedKey] = useState<string | null>(
        null
    );
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [finalDates, setFinalDates] = useState<RangeValue>(null);
    const [diffMonth, setDiffMonth] = useState<boolean>(false);

    const [dates, setDates] = useState<RangeValue>(null);
    const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);




    const signOutWithMessage = (value: string) => {
        auth.signOut();
        window.localStorage.removeItem("emailId");
        window.localStorage.removeItem("displayName");
        window.localStorage.removeItem("expirationTime");
        window.localStorage.removeItem("authToken");
        const msg = getEncodedData(value);
        return history.push(`/login?msg=${msg}`);
    };




    const handleRangeChange = (range: RangeValue) => {
        doReset();
        if (range && range[0] && range[1]) {
            const startDay = range[0].date();
            const endDay = range[1].date();
            const month = range[0].month() + 1;
            const year = range[0].year();
            setDateFilter({ startDay, endDay, month, year });

            setStartDate(range[0].format('YYYY-MM-DD'));
            setEndDate(range[1].format('YYYY-MM-DD'));

        } else {
            setStartDate(moment().subtract(30, 'days').format('YYYY-MM-DD'));
            setEndDate(moment().format('YYYY-MM-DD'));
        }
        setSelectedRange(range);
    }



    const handleCalanderChange = (range: RangeValue) => {
        if (range && range[0] && range[1]) {
            setFinalDates(range);
            setDates(null);
            setIsDropdownOpened(false)
            return;
        }
        setDates(range)
    }
    const onPanelChange = (value: RangeValue) => { }

    const disabledDate = (current: Moment) => {
        if (current.isAfter(moment(), 'day')) return true;  //future dates
        return false;
    };



    function structureData() {
        let filteredArray;
        if (columns.length === 0 && filteredCol === null) {
            filteredArray = ticketsData?.map((ticket: any, index: number) => {
                return header.reduce((acc: any, curr: string) => {
                    acc[curr] = ticket[curr]
                    return acc;

                }, {})
            })
        }
        else if (columns.length > 0 && filteredCol === null) {

            filteredArray = ticketsData?.map((ticket: any, index: number) => {
                return header.reduce((acc: any, curr: string) => {
                    acc[curr] = ticket[curr]
                    return acc;

                }, {})
            })
        }

        else if (columns.length === 0 && filteredCol !== null) {

            filteredArray = ticketsData?.filter((ticket: any) =>
                filteredCol &&
                ticket[filteredCol] >= (enteredNumber || 0)
            ).map((ticket: any, index: number) => {
                return header.reduce((acc: any, curr: string) => {
                    acc[curr] = ticket[curr]
                    return acc;
                }, {})
            })
        }
        else {
            filteredArray = ticketsData?.filter((ticket: any) =>
                filteredCol &&
                ticket[filteredCol] >= (enteredNumber || 0)
            ).map((ticket: any, index: number) => {
                return header.reduce((acc: any, curr: string) => {
                    acc[curr] = ticket[curr]
                    return acc;
                }, {})
            })
        }
        setFilteredData(filteredArray);
    }


    const getTicketsList = async (params: RecruitDateRange['data']) => {
        setIsTableLoading(true);
        setIsLoading(true);
        try {
            const response: any =
                await new RecruiteBoardTicketDuration().getTicketList(params);

            setTicketsData(response.data?.recruite_board_info);
            setRecruitmentBoardTicketURL(response.data?.recruitment_board_ticket_url);

            let recruite_board_details = response.data?.recruite_board_info;

            let store = recruite_board_details?.map((ticket: any, index: number) => {
                return header.reduce((acc: any, curr: string) => {
                    acc[curr] = ticket[curr]
                    return acc;
                }, {})
            })

            setFilteredData(store);
            setError(null);

        } catch (error: any) {
            setIsTableLoading(false);
            setIsLoading(false);
            if (error.code === 401) {
                signOutWithMessage("You are not authorized");
            }
            setError({
                ...error,
                refreshAction: getTicketsList,
            });
        } finally {
            setIsTableLoading(false);
            setIsLoading(false);
        }
    };


    useEffect(() => {
        getTicketsList({ "start_day": startDate, "end_day": endDate });
    }, [startDate, endDate, dateFilter.startDay, dateFilter.endDay])



    useEffect(() => {
        structureData();
    }, [columns, filteredCol, enteredNumber])







    const exportToExcelFile = (data: any[]) => {
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0'); // Get day with leading zero if needed
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Get month with leading zero if needed (Note: January is 0)
        const year = currentDate.getFullYear(); // Get full year (4 digits)
        const formattedDate = `${day}-${month}-${year}`;
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Recruitment Report");

        const headerColumn: Partial<ExcelJS.Column>[] | { header: any; key: any; width: number; }[] = [];
        const headerColumns: string[] = Object.keys(data[0])
        const headers = headerColumns.map(key => {
            return key.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())
        });


        headers.map((header: string) => {
            let obj = {
                header: header,
                key: header,
                width: header === 'Description' ? 60 : header == 'Position' ? 25 : 15
            }
            headerColumn.push(obj)
        });


        sheet.columns = headerColumn;

        data?.forEach((curr_data: any) => {
            const formattedItem = {};
            headerColumns.forEach((key) => {
                const header = key.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase()); // Key that matches the headers
                formattedItem[header] = curr_data[key]; // Value from the original item
            });
            const row = sheet.addRow(formattedItem);
            row.alignment = { wrapText: true, vertical: 'top', horizontal: 'left' };

            [5, 6, 7, 8].map((elem) => {
                if (row.getCell(elem)) {
                    row.getCell(elem).alignment = { wrapText: true, vertical: 'top', horizontal: 'right' };
                }
            })
        })

        sheet.getRow(1).font = { bold: true };
        sheet.getRow(1).alignment = { vertical: 'top', horizontal: 'left' };

        workbook.xlsx.writeBuffer().then((data: any) => {
            const blob = new Blob([data], {
                type: "application/vvnd.openxmlformats-officedocument.spreadsheet.sheet"
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `recruitment_report_${formattedDate}.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }


    const applyFilterHandler = (filterOptions: IFilterData) => {
        // Set selected options and filter parameters
        setSelectedOptions(filterOptions['Show Columns']);
        setColumns(filterOptions['Show Columns']);
        setEnteredNumber(filterOptions['Filtered Columns'] != 'undefined' ? filterOptions['Days'] : 0)
        setFilteredCol(filterOptions['Filtered Columns'] ? filterOptions['Filtered Columns'] : null)

        setHeader(filterOptions['Show Columns'].length === 0 ?
            [
                'ticket_id',
                'name',
                'position',
                'description',
                'screening',
                'interviewing',
                'machine_test',
                'hr_round'
            ]
            :
            filterOptions['Show Columns']
        )

    }

    const resetFilterHandler = (filterOptions: IFilterData | null) => {
        setSelectedOptions([]);
        setColumns([]);
        setEnteredNumber(0)
        setFilteredCol(null)
        setHeader([
            'ticket_id',
            'name',
            'position',
            'description',
            'screening',
            'interviewing',
            'machine_test',
            'hr_round'
        ])
    };




    const doReset = () => {
        const _filteredOptions: any = {};
        drawerInputs
            .filter((each) => each.show === undefined || each.show === true)
            .forEach((each) => {
                if (each.type === 'singleSelect' && each.defaultValue === null) {
                    _filteredOptions[each.keyName] = null;
                }
                if (each.type === 'text') {
                    _filteredOptions[each.keyName] = 0;
                }
                if (each.type === "multiSelect") {
                    _filteredOptions[each.keyName] = each.defaultValue
                        ? each.defaultValue
                        : [];
                }
                if (each.type === "checkbox") {
                    _filteredOptions[each.keyName] = each.defaultValue
                        ? each.defaultValue
                        : false;
                }
            });
        resetFilterHandler(_filteredOptions);
        setFilteredOptions(_filteredOptions);
        setOpen(false);
        setIsFilterApplied(false);
    };


    function handleSortClick(header: string) {
        setSort({
            keyToSort: header,
            direction:
                header === sort.keyToSort ? sort.direction === 'asc' ? 'desc' : 'asc' : 'desc'
        })
    }


    function getSortArray(arrayToSort: any) {
        if (sort.direction === 'asc') {
            return arrayToSort?.sort((a: any, b: any) => (a[sort.keyToSort] > b[sort.keyToSort] ? 1 : -1));
        }
        return arrayToSort?.sort((a: any, b: any) => (a[sort.keyToSort] > b[sort.keyToSort] ? -1 : 1));
    }


    const getIconClass = (column: string) => {
        if (sort.keyToSort === column) {
            return sort.direction === 'asc' ? 'da iconSort icon-sort-asc' : 'da iconSort icon-sort-desc';
        }
        return 'da iconSort icon-sort-asc';
    }

    return (
        <LoadingSpinner isLoading={isLoading ? isLoading : false}>
            <div className="mainInnerLayout" >
                <div className="innerHeader" style={{ 'paddingTop': '20px' }}>
                    <div className="ant-row alignItemsCenter">
                        <div className="ant-col ant-col-xl-16 flx justifyContentBetween alignItemsCenter">
                            <h3 style={{ width: "50%" }}>
                                {Constants.RECRUITMENT_BOARD}
                            </h3>
                        </div>
                        <div className="ant-col ant-col-xl-8">



                            <div className="innerHeaderRight flx alignItemsFlexEnd">

                                <div style={{ 'marginRight': '18px' }}>
                                    <DateRangePicker
                                        disabled={isLoading}
                                        value={selectedRange}
                                        handleRangeChange={handleRangeChange}
                                        disabledDate={disabledDate}
                                        dates={dates}
                                        setDates={setDates}
                                        finalDates={finalDates}
                                        setFinalDates={setFinalDates}
                                        diffMonth={diffMonth}
                                        setDiffMonth={setDiffMonth}
                                        handleCalanderChange={handleCalanderChange}
                                        onPanelChange={onPanelChange}
                                        isDropdownOpened={isDropdownOpened}
                                        setIsDropdownOpened={setIsDropdownOpened}
                                    />
                                </div>
                                <Button
                                    className="addUserBtn mr-10 custom-export"
                                    icon={<DownloadOutlined
                                        style={{ color: 'white', 'paddingTop': '3px' }}
                                        onPointerOverCapture={() => { }} onPointerMoveCapture={() => { }} />}
                                    onClick={() => exportToExcelFile(filteredData)}>Export to XLSX</Button>


                                <RecruitmentDrawer
                                    disabled={false}
                                    drawerInputs={drawerInputs}
                                    onApplyFilter={applyFilterHandler}
                                    onResetFilter={resetFilterHandler}
                                    filteredOptions={filteredOptions}
                                    setFilteredOptions={setFilteredOptions}
                                    currentChangedKey={currentChangedKey}
                                    setCurrentChangedKey={setCurrentChangedKey}
                                    doReset={doReset}
                                    open={open}
                                    setOpen={setOpen}

                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="customTbl userManagementTbl scroll-y tblStickyTHead timeDelayReport">
                    <table className="main-table">
                        <thead>
                            <tr>
                                {
                                    header.map((elem, index) => {

                                        return (
                                            <th
                                                key={index}
                                                className={`${keyToClassMap[elem]} ${(elem === 'screening' || elem === 'interviewing' || elem === 'hr_round' || elem === 'machine_test') ? 'text-right' : 'text-left'}`}
                                                style={{ minWidth: (elem === 'name' || elem === 'position' || elem === 'description') ? '160px' : '60px', paddingRight: `${elem === 'hr_round' ? '20px' : '0'}` }}
                                            >
                                                {elem.split('_').join(' ')}
                                                {(elem === 'position' || elem === 'screening' || elem === 'interviewing' || elem === 'hr_round' || elem === 'machine_test') && (
                                                    <span onClick={() => handleSortClick(elem)} className={getIconClass(elem)} ></span>
                                                )}
                                            </th>
                                        )
                                    })

                                }
                            </tr>

                        </thead>
                        <tbody>
                            {
                                getSortArray(filteredData)?.map((ticket: any) => {

                                    return (
                                        <tr key={ticket['ticket_id']}>
                                            {
                                                header.map((key, index: number) => {
                                                    if (key === 'ticket_id') {
                                                        return (
                                                            <td className={keyToClassMap[key]} key={index} >
                                                                <a
                                                                    href={`${recruitmentBoardTicketURL}${ticket[key]}`}
                                                                    target="_blank"
                                                                    style={{ textDecoration: 'none', 'color': '#4171b9', 'fontSize': '14px' }} rel="noreferrer"
                                                                >
                                                                    {ticket.ticket_id}
                                                                </a>
                                                            </td>
                                                        )
                                                    }
                                                    else if (key === 'position' || key === 'description') {
                                                        return (
                                                            <td
                                                                className={keyToClassMap[key]}
                                                                style={{ left: "0" }}
                                                                key={index}
                                                            >
                                                                {ticket[key] === '' || ticket[key] === null ? '-' : ticket[key]}
                                                            </td>
                                                        )
                                                    }
                                                    else if (key === 'name') {
                                                        return (<td
                                                            className={`${keyToClassMap[key]} 
                                                            ${key !== 'name' ? 'text-right' : 'text-left'}`}
                                                            style={{ left: "0", paddingRight: '16px'} }
                                                            key={index}
                                                            >
                                                                {ticket[key]?.split(' ')
                                                                .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                                .join(' ') ?? '-'}
                                                            </td>)
                                                    }
                                                    else {
                                                        return (
                                                            <td
                                                            className={`${keyToClassMap[key]} 
                                                            ${key !== 'name' ? 'text-right' : 'text-left'}`}
                                                            style={{ left: "0", paddingRight: `${key === 'hr_round' ? '36px' : '16px'}` }}
                                                            key={index}
                                                            >
                                                                {ticket[key] ?? '-'}
                                                            </td>)
                                                    }
                                                })
                                            }
                                        </tr>)
                                })
                            }
                            {(!filteredData || filteredData.length === 0) && <NoDataFound />}
                        </tbody>
                    </table>

                </div>

            </div>
        </LoadingSpinner>
    );
};

export default RecruitmentBoard;

