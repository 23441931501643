import React, { useEffect } from "react";
import { Select } from "antd";
import { IFilterDrawerInputProps } from "models/interfaces";

interface i {
  drawerInput: IFilterDrawerInputProps;
  filteredOptions: any;
  onChange: (filed: string, _value: any[]) => void;
  currentChangedKey: string | null;
  
}
const FilterDrawerMultiSelect: React.FC<i> = ({ drawerInput, filteredOptions, onChange, currentChangedKey }) => {
  const handleChange = (value: string[]) => {
    onChange(drawerInput.keyName, value);
  };

  const getOptions = () => {
    let _options = drawerInput?.options ? [...drawerInput?.options] : []; //NOSONAR
    if (
      drawerInput.cascadeKeyName &&
      filteredOptions[drawerInput.cascadeKeyName]
    ) {
      let checkValue = filteredOptions[drawerInput.cascadeKeyName];
      if (!Array.isArray(filteredOptions[drawerInput.cascadeKeyName])) {
        checkValue = [filteredOptions[drawerInput.cascadeKeyName]];
      }
      _options = _options.filter((each) => {
        if (checkValue.indexOf(each.parentId) !== -1) {
          return true;
        }
        return false;
      });
    }
    return _options;
  };

  useEffect(() => {
    if (currentChangedKey && currentChangedKey === drawerInput.cascadeKeyName) {
      onChange(drawerInput.keyName, []);
    }
  }, [currentChangedKey]);
  

  return (
    <React.Fragment>
      <div className="inputWrap">
        <label>{drawerInput.label}</label>
        <Select
          mode="multiple"
          showSearch
          showArrow
          placeholder="Please select"
          value={filteredOptions[drawerInput.keyName]}
          onChange={handleChange}
          filterOption={(input, option) =>
            option?.label?.toLowerCase().includes(input?.toLowerCase())
              ? true
              : false
          }
          style={{ width: "100%" }}
          options={getOptions()}
          allowClear
        />
      </div>
    </React.Fragment>
  );
};

export default FilterDrawerMultiSelect;
