import React from "react";
import { Button, Drawer, Space, Tooltip} from "antd";
import { IFilterDrawerProps } from "models/interfaces/TeamMembers/teamMembers.model";
import TeamMembersSingleSelect from "./drawerComponents/CommonDrawerSingleSelect";

const CommonDrawer: React.FC<IFilterDrawerProps> =
    ({ filterData, setFilterData, cancelFilter, submitFilter, isFilterOpen, setIsFilterOpen, drawerInputs, title, defaultFilterParams, disabled }) => {
        
        const showDrawer = () => {
            setIsFilterOpen(true);
        };
        const onClose = () => {
            setIsFilterOpen(false);
            setFilterData(defaultFilterParams())
        };
        const handleSelectChange = (key: string, value: string | null) => {
            
            setFilterData({...filterData, [key]: value });
        }
        const enableSubmit = () => {
            if (filterData.status) return true;
            return false;
        }
        return (
            <React.Fragment>
                <Button
                disabled={disabled}
                type="text"
                className={`dd-btn-toggle filterBtn`}
                onClick={showDrawer}
                >
                    {filterData.status !== 'all' ? (
                        <div className="dot"></div>
                    ) : (
                        <></>
                    )}
                    <i className="da icon-listview listviewIcon"></i>
                </Button>
                <Drawer
                    title={title}
                    placement="right"
                    onClose={onClose}
                    visible={isFilterOpen}
                    className="filterDrawerWrap drawerCustom"
                    closeIcon={
                        <Tooltip title="Close Filter Drawer" placement="left">
                            <i className="da icon-arrow-right-dark"></i>
                        </Tooltip>
                    }
                >
                    {
                        drawerInputs.map((curr) => {
                                return (
                                    <TeamMembersSingleSelect
                                    key={curr.keyName}
                                    drawerInput={curr}
                                    handleChange={handleSelectChange}
                                    filterData = {filterData}
                                    />
                                )
                            
                        })
                    }
                    <Space wrap className="btnsRight" style={{ marginTop: '20px' }}>
                        <Button type="link" onClick={cancelFilter} className="linkBtn">RESET</Button>
                        <Button type="primary" onClick={submitFilter} disabled={!enableSubmit()}>Apply</Button>
                    </Space>
                </Drawer>
            </React.Fragment>
        );
    };
export default CommonDrawer;