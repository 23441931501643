import { DatePicker } from 'antd';
import type { Moment } from 'moment';
import React, { useEffect } from 'react';
import { RangeValue } from 'models/types/Reports/DateRangeSelector';
import moment from 'moment';
const { RangePicker } = DatePicker;


interface IDateRangePicker {
    value: RangeValue,
    disabledDate: (current: Moment) => boolean,
    handleRangeChange: (value: RangeValue) => void,
    dates:RangeValue,
    isDropdownOpened: boolean
    setIsDropdownOpened: React.Dispatch<React.SetStateAction<boolean>>
    setDates: React.Dispatch<React.SetStateAction<RangeValue>>
    finalDates:RangeValue,
    setFinalDates: React.Dispatch<React.SetStateAction<RangeValue>>
    diffMonth: boolean
    setDiffMonth?: React.Dispatch<React.SetStateAction<boolean>>
    handleCalanderChange: (range: RangeValue) => void
    onPanelChange: (value: RangeValue) => void,
    disabled: boolean
}

const DateRangePicker: React.FC<IDateRangePicker> = 
({value, handleRangeChange, disabledDate, dates, setDates, finalDates, setFinalDates, diffMonth, setDiffMonth, onPanelChange, handleCalanderChange, isDropdownOpened, setIsDropdownOpened, disabled}) => {


  useEffect(() => {
    moment.updateLocale('en', {
        weekdaysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    });
});


  return (
    <div id="custom-range-picker">
      <RangePicker
        disabled={disabled}
        value={dates || value}
        open={isDropdownOpened}
        onOpenChange={(open) => setIsDropdownOpened(open)}
        disabledDate={disabledDate}
        onCalendarChange={(val) => handleCalanderChange(val)}
        onChange={handleRangeChange}
        dropdownClassName= {`${finalDates && finalDates[0] && finalDates[1] && diffMonth? 'createDateRangePicker' : ''}`}
        onPanelChange={onPanelChange}
      />
    </div>
  );
};

export default DateRangePicker;