import React, { ChangeEvent, useMemo } from "react";
import { Button, Drawer, Space, Tooltip } from "antd";
import type { Moment } from 'moment';
import moment from "moment";
import { useRbac } from "features/rbac/rbac";
import { IWfhDrawerInputProps } from "models/interfaces/WfhApply/wfhApply.model";
import WfhDrawerTextarea from "utils/WfhDrawer/WfhDrawerTextArea";
import WfhDrawerDatePicker from "utils/WfhDrawer/WfhDrawerDatePicker";
import WfhDrawerMultiselect from "utils/WfhDrawer/WfhDrawerMultiselect";
import TextArea from "antd/lib/input/TextArea";


const EditAndApproveDrawer: React.FC<any> =
    ({ wfhData, setWfhData, setIsWfhOpen, isWfhOpen, submitWfhData, cancel, weekDaysToShow }) => {
        const { biometric_emp_code } = useRbac().userDetailsInfo.sub;


        const drawerInputs: IWfhDrawerInputProps[] = [
            {
                disable: false,
                defaultValue: true,
                keyName: "start_date",
                label: "Start Date: ",
                options: [],
                default: wfhData?.start_date,
                type: 'datepicker'
            },
            {
                disable: false,
                defaultValue: true,
                keyName: "end_date",
                label: "End Date: ",
                options: [],
                default: wfhData?.end_date,
                type: 'datepicker'
            },
            {
                type: "multiSelect",
                disable: false,
                defaultValue: false,
                keyName: "days",
                label: "Days",
                options: weekDaysToShow.sort((curr: any, next: any) => curr.value - next.value),
                default: ''
            },
            {
                disable: false,
                defaultValue: false,
                keyName: "reason",
                label: "Application Reason",
                options: [],
                default: '',
                type: 'text'
            },
            {
                disable: false,
                defaultValue: false,
                keyName: "edit_reason",
                label: "Reason",
                options: [],
                default: '',
                type: 'textarea'
            }
        ]


        const showDaysSelecter = useMemo(() => {
            if (wfhData.start_date && wfhData.end_date) {
                let weekdayCount = 0;
                let start = wfhData.start_date.clone();
                let end = wfhData.end_date.clone();

                while (start.isSameOrBefore(end)) {
                    if (start.weekday() !== 0 && start.weekday() !== 6) {
                        weekdayCount++;
                    }
                    start.add(1, 'days');
                }

                return weekdayCount > 2;
            };
            return false;
        }, [wfhData.start_date, wfhData.end_date]);


        // Disable start dates
        const disabledStartDate = (current: Moment | null): boolean => {
            const today = moment().startOf('day');
            if (!current) return false;

            if (current.weekday() === 0 || current.weekday() === 6) return true;

            if (current.isBefore(today)) return true;

            return !!(wfhData.end_date && current.isAfter(wfhData.end_date, 'day'));
        };


        // Disable end dates
        const disabledEndDate = (current: Moment | null): boolean => {
            const today = moment().startOf('day');
            if (!current) return false;

            if (current.weekday() === 0 || current.weekday() === 6) return true;

            if (current.isBefore(today)) return true;

            return !!(wfhData.start_date && current.isBefore(wfhData.start_date, 'day'));
        };


        const defaultWfhData = () => ({ start_date: null, end_date: null, edit_reason: '', id: biometric_emp_code, document: null, days: null })


        const handleChange = (keyName: string, value: any, e?: ChangeEvent<HTMLTextAreaElement> | undefined) => {
            let updatedValue = value;

            if (keyName === 'days') {

                if (updatedValue.length === weekDaysToShow.length || updatedValue.length === 0)
                    updatedValue = null;
                else
                    updatedValue = value.sort((curr: number, next: number) => curr - next);
            }

            if ((keyName === 'start_date' || keyName === 'end_date') && (wfhData.start_date && wfhData.end_date) && wfhData.start_date.diff(wfhData.end_date, 'days')) {
                setWfhData({ ...wfhData, [keyName]: updatedValue, days: null });
                return;
            }

            setWfhData({ ...wfhData, [keyName]: updatedValue });
        }

        const onClose = () => {
            setIsWfhOpen(false);
            setWfhData(defaultWfhData())
        };

        const enableSubmit = () => wfhData.start_date && wfhData.end_date



        return (
            <Drawer
                title="Work From Home"
                placement="right"
                onClose={onClose}
                visible={isWfhOpen}
                className="filterDrawerWrap drawerCustom"

                closeIcon={
                    <Tooltip title="Close Filter Drawer" placement="left">
                        <i className="da icon-arrow-right-dark"></i>
                    </Tooltip>
                }
            >
                {
                    drawerInputs.map((curr) => {


                        if (curr.type === 'datepicker') {
                            return (
                                <WfhDrawerDatePicker
                                    drawerInput={curr}
                                    handleChange={handleChange}
                                    wfhData={wfhData}
                                    key={curr.keyName}
                                    disabledDate={curr.keyName === 'start_date' ? disabledStartDate : disabledEndDate}
                                />
                            )
                        }
                        if (curr.type === 'multiSelect') {

                            if (showDaysSelecter)
                                return (
                                    <WfhDrawerMultiselect
                                        key={curr.keyName}
                                        drawerInput={curr}
                                        handleChange={handleChange}
                                        wfhData={wfhData}
                                    />
                                );
                            else
                                return null;
                        }
                        if (curr.type === 'textarea') {
                            return (
                                <WfhDrawerTextarea
                                    labelText='Comments'
                                    key={curr.keyName}
                                    drawerInput={curr}
                                    handleChange={handleChange}
                                    wfhData={wfhData}
                                    setWfhData={setWfhData}
                                    isNotRequired={true}
                                />
                            );
                        }
                        return (
                            <div className="inputWrap" key={curr?.keyName}>
                                <label>{curr.label}:</label>
                                <TextArea
                                    disabled={true}
                                    style={{ color: 'black' }}
                                    value={wfhData[curr?.keyName]}
                                />
                            </div>
                        )

                    })
                }

                <Space wrap className="btnsRight" style={{ marginTop: '20px' }}>
                    <Button type="link" onClick={cancel} className="linkBtn">Cancel</Button>
                    <Button type="primary" onClick={() => submitWfhData('approved', [wfhData.id], wfhData)} disabled={!enableSubmit()}>Approve</Button>
                </Space>

            </Drawer>
        );
    };

export default EditAndApproveDrawer;