import React from "react";
import { Button, Drawer, Space, Tooltip} from "antd";
import LeaveReconciliationDrawerSingleSelect from "utils/LeaveReconciliationDrawer/LeaveReconciliationDrawerSingleSelect";
import LeaveReconciliationMultiSelect from "utils/LeaveReconciliationDrawer/LeaveReconciliationDrawerMultiSelect";
import { ILeaveReconciliationColumnSelect, ILeaveReconciliationFilterParams, ILeaveReconciliationVersionSelect } from "models/interfaces";

interface i {
    disabled: boolean,
    open: boolean, 
    setOpen: React.Dispatch<React.SetStateAction<boolean>>, 
    dropdownOptions: {
        columns: ILeaveReconciliationColumnSelect [];
        versions: ILeaveReconciliationVersionSelect [];
    },
    tempFilterParams: ILeaveReconciliationFilterParams, 
    setTempFilterParams: React.Dispatch<React.SetStateAction<ILeaveReconciliationFilterParams>>, 
    filterParams: ILeaveReconciliationFilterParams, 
    setFilterParams: React.Dispatch<React.SetStateAction<ILeaveReconciliationFilterParams>>
}


const LeaveReconciliationDrawer:React.FC<i> = 
({disabled, open, setOpen, dropdownOptions,tempFilterParams, setTempFilterParams, filterParams, setFilterParams}) => {


  const onChange = (key: string, value: string[]) => {
    setTempFilterParams({
        ...tempFilterParams,
        [key.toLowerCase()]: (key === 'version' && value === undefined)? null : value
    })
  }
  
  const submitLeaveReconciliationData = () => {
    setFilterParams(tempFilterParams)
    setOpen(false);
  }

  
  const cancleDrawerSelection = () => {
    setOpen(false);
    setFilterParams({...filterParams, columns: [], version: null})
    setTempFilterParams({...tempFilterParams, columns: [], version: null})
  }

  
  return (
    <React.Fragment>
        <button
            className={`dd-btn-toggle filterBtn ${disabled ? "disabled" : ""}`}
            onClick={() => setOpen(true)}
            disabled={disabled}
            style={{background: 'transparent'}}
        >
         {(filterParams.columns.length > 0 || (filterParams.version && filterParams.version !== ''))? (
          <div className="dot"></div>
        ) : (
          <></>
        )}
          <i className="da icon-listview listviewIcon "></i>
        </button>

        <Drawer
            title='FILTER OPTIONS'
            placement="right"
            onClose={() => setOpen(false)}
            visible={open}
            className="filterDrawerWrap drawerCustom"
            closeIcon={
            <Tooltip title="Close Filter Drawer" placement="left">
                <i className="da icon-arrow-right-dark"></i>
            </Tooltip>
            }
        >
            <LeaveReconciliationMultiSelect 
            tempFilterParams={tempFilterParams}
            dropdownOptions={dropdownOptions.columns}
            keyName='Columns'
            onChange={onChange}
            />

            <LeaveReconciliationDrawerSingleSelect 
            tempFilterParams={tempFilterParams}
            dropdownOptions={dropdownOptions.versions}
            keyName='Version'
            onChange={onChange}
            />
        
            <Space wrap className="btnsRight">
              <Button type="link" onClick={cancleDrawerSelection} className="linkBtn">Reset</Button>
              <Button type="primary" onClick={submitLeaveReconciliationData}>Submit</Button>
            </Space>

        </Drawer>
    </React.Fragment>
  );
};

export default LeaveReconciliationDrawer;