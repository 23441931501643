import { Layout } from "antd";
import { usePageTitle } from "hooks";
export function NotFound() {
  usePageTitle("Not Found");
    return (
        <Layout className="mainInnerLayout" style={{height: '100%'}}>          
            <h3>404 Error</h3>
            <div className="errorContent">
              <div className="errorContentInner">
                      <span className="error-img"></span>
                      <div>
                      <h3>Something's wrong.</h3>
                      <p>You’ve landed on a 404 error.
                      This could mean that you’ve clicked on a bad link,
                      or entered an invalid URL.</p>

                      <p>Try going back to the previous page.</p>
                      </div>
              </div>   
            </div>          
          </Layout>
    );
  }